<template>
	<transition name="fade">
		<CleanPopup
			v-if="componentErrorPopup"
			can-close
			cross
			aria-label="New Update (Popup)"
			@close="closeComponentErrorPopup"
		>
			<div class="component-err">
				<div class="component-err__caption">
					Yoo-Hoo!<br>
					Dripify has been just updated to a newer version. Click the button below to refresh the
					page:
				</div>
				<button
					class="btn btn--accent btn--big"
					@click="refreshPage"
				>
					Refresh
				</button>
				<br>
				<p class="small">
					P.S. Some user interface elements might not be displayed properly until you refresh the
					page
				</p>
			</div>
		</CleanPopup>
	</transition>
</template>

<script>
export default {
	name: "ComponentError",
	data: () => ({
		componentErrorPopup: true
	}),
	methods: {
		closeComponentErrorPopup() {
			this.componentErrorPopup = false;
		},
		refreshPage() {
			window.multitabChannel.postMessage("refresh"); // multireload (see in App.vue)
			document.location.reload(true);
		}
	}
};
</script>

<style lang="sass">
.component-err
	max-width: 470px
	width: 100%
	text-align: center
	padding: 35px 20px
	&__
		&caption
			font-size: 22px
			font-weight: 500
			line-height: 1
			margin-bottom: 20px
</style>
