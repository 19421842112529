<template>
	<div class="search-validator">
		<label
			for="LinkedInSearch"
			class="field__label search-validator__label-inner"
		>
			<span>
				Filter profiles in the
				<a
					href="https://www.linkedin.com/search/results/people/"
					target="_blank"
				>LinkedIn search</a>
				and paste the URL below
			</span>
			<InfoTip
				tip="You can filter profiles in the search page of LinkedIn Basic, Sales Navigator and Recruiter Lite"
			/>
		</label>
		<TextInput
			v-model.trim="url"
			input-name="LinkedInSearch"
			ph="https://www.linkedin.com/search..."
			:validation="{ regex: linkRegExp, required: true }"
			type="url"
			:error-text="showErrMessage ? errMsg || 'This LinkedIn Search URL cannot be supported' : null"
			@input="validate"
			@onValidationStateChange="onValidationStateChange"
		/>
		<span
			v-if="validValue"
			class="search-validator__icon"
		>
			<InlineSpinner v-if="waitingSpinner" />
			<icon
				v-else-if="okResponse"
				icon="checkmark"
				:w="15"
				:h="15"
			/>
		</span>

		<transition name="fade">
			<WrongContract
				v-if="errorPopup === 'SEARCH_WRONG_CONTRACT' || errorPopup === 'SEARCH_2FA_IS_NOT_ENABLED'"
				key="1"
				@change="closePopupAndValidate"
				@close="closeErrorPopup"
			/>
			<SearchGenericError
				v-else-if="errorPopup === 'SEARCH_GENERIC_ERROR'"
				key="2"
				:search-url="url"
				@close="closeErrorPopup"
			/>
			<SearchSessionIsTaken
				v-else-if="errorPopup === 'SEARCH_SESSION_IS_TAKEN'"
				key="3"
				@sessionsCleared="closePopupAndValidate"
				@close="closeErrorPopup"
			/>
		</transition>
	</div>
</template>

<script>
export default {
	name: "SearchValidator",
	components: {
		WrongContract: () => window.compLoader(import("@/js/components/ContractPopups/WrongContract")),
		SearchGenericError: () =>
			window.compLoader(import("@/js/components/Campaign/Audience/ErrorPopups/SearchGenericError")),
		SearchSessionIsTaken: () =>
			window.compLoader(
				import("@/js/components/Campaign/Audience/ErrorPopups/SearchSessionIsTaken")
			)
	},
	model: {
		prop: "value",
		event: "input"
	},
	data: () => ({
		url: "",
		isValid: true,
		timeout: null,
		okResponse: false,
		waitingSpinner: false,
		linkRegExp: /http(s)?:\/\/([\w]+\.)?linkedin\.com\/[A-z0-9_-]+\/?/,
		errMsg: "",
		errorPopup: ""
	}),
	computed: {
		validValue() {
			return this.isValid && this.url;
		},
		showErrMessage() {
			return !this.okResponse && this.url && !this.waitingSpinner;
		}
	},
	methods: {
		validate(val) {
			this.url = val;
			this.okResponse = false;
			this.waitingSpinner = false;
			this.$emit("input", this.url);
			setTimeout(this.startPendingTimeout, 0);
		},
		startPendingTimeout() {
			clearTimeout(this.timeout);
			if (this.validValue) {
				this.waitingSpinner = true;
				this.timeout = setTimeout(this.searchRequest, 1000);
			} else {
				this.emitValidationStatus({});
			}
		},
		async searchRequest() {
			try {
				const res = await this.$store.dispatch("getSearchType", {
					url: this.url
				});
				this.okResponse = true;
				this.emitValidationStatus(res);
			} catch (err) {
				const error = err.response.data;
				this.errMsg = error.status !== 500 && error.status !== 409 ? error.message : "";
				if (error.status === 409) {
					this.errorPopup = error.message;
				}
				this.emitValidationStatus({});
				this.okResponse = false;
				throw err;
			} finally {
				this.waitingSpinner = false;
			}
		},
		onValidationStateChange(val) {
			this.isValid = val;
		},
		emitValidationStatus(val) {
			this.$emit("statusChange", val);
		},
		closeErrorPopup() {
			this.errorPopup = "";
		},
		closePopupAndValidate() {
			this.validate(this.url);
			this.closeErrorPopup();
		}
	}
};
</script>

<style lang="sass">
.search-validator
	position: relative
	input
		padding-right: 35px
	&__
		&icon
			position: absolute
			bottom: 10px
			height: 15px
			right: 15px
			z-index: 2
			width: 15px
		&label-inner
			display: flex !important
			align-items: center
			gap: 5px
			width: 100%
</style>
