import { EF_BEST_PLAN_ID } from "@/js/helpers/constants/constants";

export default {
	state: {
		emailFinder: null
	},
	getters: {
		emailFinder: (s) => s.emailFinder,
		efSubscription: (s, g) => s.emailFinder && g.emailFinder.subscription,
		efPlanId: (_, g) => g.efSubscription && g.efSubscription.priceId,
		efSubscriptionStatus: (_, g) => g.efSubscription && g.efSubscription.status,
		efSubscriptionBuyerIsCurrentUser: (_, g) =>
			g.efSubscription && g.currentUser && g.efSubscription.buyerId === g.currentUser.id,
		allowToUpgradeEf(_, g) {
			const notBestPlanBoughtByMyself =
				g.efPlanId !== EF_BEST_PLAN_ID && g.efSubscriptionBuyerIsCurrentUser;
			const badStatus =
				g.efSubscriptionStatus === "deactivated" || g.efSubscriptionStatus === "canceled";

			return !g.efSubscription || notBestPlanBoughtByMyself || badStatus;
		},
		allowedToBuyEf: (_, g) =>
			g.emailFinder &&
			g.subscription &&
			(g.subscriptionStatus === "active" || g.subscriptionStatus === "canceled") &&
			!g.efScheduledForPause,
		efScheduledForPause: (_, g) => g.efSubscriptionStatus && g.emailFinder.pausedAt
	},
	mutations: {
		setEmailFinderData(state, data) {
			state.emailFinder = data;
		}
	},
	actions: {
		async getMyEmailFinder(store) {
			try {
				const myId = store.getters.currentUser.id;
				const res = await window.request("GET", `/v1/shared/emailcredits/${myId}`);
				store.commit("setEmailFinderData", res.data);
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getEmailFinderById(store, userId) {
			try {
				const res = await window.request("GET", `/v1/shared/emailcredits/${userId}`);
				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		}
	}
};
