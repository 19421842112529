export default {
	data: () => ({
		removingPopup: false
	}),
	methods: {
		openRemovingPopup() {
			this.removingPopup = true;
		},
		closeRemovingPopup() {
			this.removingPopup = false;
		}
	}
};
