import auth from "./auth";
import firebaseModule from "./firebase";
import billing from "./billing";
import emailFinder from "./emailFinder";
import team from "./team";
import campaigns from "./campaigns/campaigns";
import sequenceTemplates from "./sequenceTemplates";
import messages from "./messages";
import leads from "./leads";
import statistics from "./statistics";
import marketing from "./marketing";
import notifications from "./notifications";
import settings from "./settings";
import support from "./support";
import tooltips from "./tooltips";
import sequenceEventFilters from "./sequenceEventFilters";
import admin from "./admin";
import contracts from "./contracts";
import preferences from "./preferences";
import identity from "./identity";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth,
		firebaseModule,
		campaigns,
		sequenceTemplates,
		leads,
		team,
		tooltips,
		messages,
		settings,
		marketing,
		statistics,
		notifications,
		sequenceEventFilters,
		billing,
		emailFinder,
		support,
		preferences,
		admin,
		contracts,
		identity
	}
});
