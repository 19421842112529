export default {
	actions: {
		async getSearchLeadsConfigItems(store, params) {
			try {
				const res = await window.request("GET", "/searches", params);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getSearchLeadsConfigItem(store, id) {
			try {
				const res = await window.request("GET", `/searches/${id}`);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async createSearchLeadsConfigItem(store, payload) {
			try {
				const res = await window.request("POST", "/searches", payload);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async deleteSearchLeadsConfigItem(store, id) {
			try {
				await window.request("DELETE", `/searches/${id}`);
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getSearchLeadConfigStatistics(_, ids) {
			const url = `/v1/search-lead-config-item/statistics/${ids}`;
			const res = await window.request("GET", url);

			return res.data;
		},
		async getSearchType(_, payload) {
			const res = await window.request("POST", "/searches/validation", payload);

			return res.data;
		}
	}
};
