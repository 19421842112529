import axios from "axios";
import axiosInterceptorsSetup from "@/js/helpers/api/axiosInterseptor";

async function request(type, endpoint, data = {}, config = {}) {
	const reuqestType = type.toLowerCase();
	const dataIsParams = !dataHasConfigKeys(data) && reuqestType === "get";
	const url = normalizeRuqestURL(endpoint, dataIsParams ? data : false);
	const dataToPass = dataIsParams ? {} : data;

	return await requestExecutor(reuqestType, url, dataToPass, config);
}

function dataHasConfigKeys(data) {
	if (!data || typeof data === "string" || Array.isArray(data)) return false;
	const configKeys = ["headers", "responseType"];

	return Object.keys(data).some((key) => configKeys.find((item) => item === key));
}

function normalizeRuqestURL(endpoint, params) {
	endpoint = endpoint.startsWith("/") ? endpoint : "/" + endpoint;
	const url = new URL(process.env.VUE_APP_SERVER_PATH + endpoint);
	if (params) url.search = new URLSearchParams(params);
	return url.href;
}

async function requestExecutor(type, url, data, config) {
	if (type === 'get') {
		return await axios.get(url, config || data);
	}
	return await axios[type](url, data, config);
}

window.request = request;
axiosInterceptorsSetup();
