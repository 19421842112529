<template>
	<div
		:class="className"
		role="tablist"
	>
		<nav :class="className + '__nav'">
			<div
				:class="className + '__selector'"
				:style="selectorPosition"
			/>
			<ul :class="className + '__list'">
				<li
					v-for="(tablink, index) in tabs"
					:key="index"
				>
					<a
						:href="'#' + tablink.computedId"
						role="tab"
						:aria-selected="tablink.isActive"
						:aria-controls="tablink.computedId"
						:class="[
							tablink.isActive ? `${className}__link--active` : null,
							className + '__link',
							tablink.disabled ? className + '__link--disabled' : null
						]"
						@click.prevent="selectTab(tablink)"
						v-html="tablink.name"
					/>
				</li>
			</ul>
		</nav>
		<slot role="tabpanel" />
	</div>
</template>

<script>
export default {
	name: "Tabs",
	props: {
		className: { type: String, default: "tabs" }
	},
	data: () => ({
		tabs: [],
		selectorPosition: {}
	}),
	destroyed() {
		window.removeEventListener("resize", this.setTabSelectorPosition);
	},
	mounted() {
		this.tabs = this.$children;
		setTimeout(this.selectTab, 100);
		window.addEventListener("resize", this.setTabSelectorPosition);
	},
	methods: {
		selectTab(selectedTab = this.tabs[0]) {
			this.$emit("change");
			this.tabs.forEach((tab) => {
				tab.isActive = tab.computedId === selectedTab.computedId;
			});
			this.setTabSelectorPosition();
		},
		setTabSelectorPosition() {
			const tabLinks = [...document.querySelectorAll(`.${this.className}__link`)];
			const selectedLink = tabLinks[this.tabs.findIndex((tab) => tab.isActive)];
			this.selectorPosition = {
				width: selectedLink.offsetWidth + "px",
				height: selectedLink.offsetHeight + "px",
				left: selectedLink.offsetLeft + "px",
				top: selectedLink.offsetTop + "px"
			};
		}
	}
};
</script>

<style lang="sass">
@import "@/styles/components/tabs/dynamic-tabs"
</style>
