const arrayToRepeatParam = (arr, arrName) => arr.map((item) => `${arrName}=${item}`).join("&");
const arrayToEnumParam = (arr, arrName) => `${arrName}=${arr.join()}`;

export function formattingParams(obj, enumType) {
	if (obj) {
		const params = Object.keys(obj).map((key) => {
			if (Array.isArray(obj[key])) {
				return enumType ? arrayToEnumParam(obj[key], key) : arrayToRepeatParam(obj[key], key);
			} else {
				return `${key}=${obj[key]}`;
			}
		});
		return params.join("&");
	}
}
