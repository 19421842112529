<template>
	<div
		class="checkbox"
		:class="{
			'checkbox--inline': haveLabel,
			'checkbox--disabled': disabled
		}"
	>
		<input
			:id="checkName"
			class="checkbox__input"
			type="checkbox"
			:name="checkName"
			:checked="checked"
			:disabled="disabled"
			:required="required"
			v-bind="$attrs"
			@change="$emit('change', $event.target.checked)"
		>
		<label
			class="checkbox__label"
			:class="{ 'checkbox__label--disabled': disabled }"
			:for="checkName"
		>
			<span
				v-if="haveLabel"
				class="checkbox__text"
				:class="{
					'checkbox__text--disabled': disabled,
					'checkbox__text--required': required
				}"
			>
				<slot />
			</span>
		</label>
	</div>
</template>

<script>
export default {
	name: "Checkbox",
	model: {
		prop: "checked",
		event: "change"
	},
	props: {
		value: { type: String, default: `checkbox${Date.now()}` },
		checkName: { type: String, required: true },
		disabled: { type: Boolean, default: false },
		checked: { type: Boolean, default: false },
		required: { type: Boolean, default: false }
	},
	computed: {
		haveLabel() {
			return this.$slots.default;
		}
	}
};
</script>

<style lang="sass">
.checkbox
	$this: &
	position: relative
	width: 20px
	height: 20px
	&--
		&inline
			margin-right: 10px
			width: auto
			height: auto
			display: inline-block
			#{$this}__label::before
					border-color: cl(base-theme)
		&disabled
			pointer-events: none
			#{$this}__label
				&::before
					border-color: cl(_gray)
					background-color: cl(easy-light-blue)
				&::after
					opacity: 0
	&__
		&input
			opacity: 0
			position: absolute
			z-index: -999
			width: 0
			height: 0
			overflow: hidden
			left: 9px
			top: 9px
			&:checked
				& + #{$this}__label
					&::before
						background-color: cl(base-theme)
						border-color: cl(base-theme)
					&::after
						opacity: 1
						transform: rotate(0deg)
				&:disabled + #{$this}__label::before
					background-color: cl(_gray)
					border-color: cl(_gray)

			&:not(:disabled):focus-visible + #{$this}__label::before
				+focus-visible()

		&label
			cursor: pointer
			line-height: 18px
			margin: 0
			&::before
				content: ''
				display: inline-block
				border: 1px solid cl(light-dirty)
				border-radius: var(--borderRadius)
				width: 18px
				height: 18px
				transition: background-color var(--transition), border-color var(--transition)
			&::after
				content: ''
				position: absolute
				width: 11px
				height: 8px
				background: no-repeat url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L3.8125 7L1 4.27273' stroke='white' stroke-width='1.4' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
				left: 4px
				top: 5px
				transform: rotate(-20deg) scale(.5)
				transition: transform var(--transition), opacity var(--transition)
				opacity: 0
		&text
			vertical-align: top
			padding-left: 9px
			user-select: none
			display: inline-block
			width: calc(100% - 18px)
			transition: color var(--transition)
			&--
				&disabled
					color: cl(_gray)
				&required
					&::after
						content: ' *'
						color: cl(accent-theme)
			a
				text-decoration: underline
				&:hover,
				&:focus
					text-decoration: none
</style>
