<template>
	<div class="picto">
		<span v-if="isDraft">{{ draftNameFirstLetter }}</span>
		<ImgComp
			v-else
			:src="campaignTypeIcon"
			:alt="campaign.name"
		/>
	</div>
</template>

<script>
export default {
	name: "CampaignAvatar",
	props: {
		campaign: { type: Object, required: true }
	},
	computed: {
		isDraft() {
			return this.campaign.status === "DRAFT";
		},
		draftNameFirstLetter() {
			return this.campaign.name[0].toUpperCase();
		},
		campaignTypeIcon() {
			return `sequence-templates/${this.campaign.type || "CUSTOM_CAMPAIGN"}.svg`;
		}
	}
};
</script>
