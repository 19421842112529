<template>
	<ValidationProvider
		v-slot="{ validate, errors, classes }"
		:rules="validation"
		:name="inputName"
		:vid="vid"
		:mode="validationMode"
		class="field"
		:class="{
			'field--inline': inline,
			'field--numeric': numeric,
			'field--limit': limit,
		}"
		tag="div"
	>
		<label
			v-if="labelText"
			class="field__label"
			:for="inputName"
		>
			{{ labelText }}
			<span
				v-if="isRequired"
				class="field__req"
				aria-label="Required"
			>*</span>
		</label>

		<div
			class="field__input-wrap"
			:class="{ 'field__input-wrap--invalid': classes.invalid }"
		>
			<input
				:id="inputName"
				:ref="inputName"
				:name="inputName"
				:type="type"
				:class="{
					'field__input--center': center
				}"
				class="field__input"
				:autocomplete="autocomplete"
				:value="value"
				:disabled="disabled"
				:inputmode="inputmode || computedMode"
				:pattern="pattern"
				@change="$emit('change', $event)"
				@input="onInput($event, validate($event))"
				@focus="$emit('focus')"
				@blur="$emit('blur')"
			>
			<label
				v-if="ph"
				:for="inputName"
				class="field__placeholder"
				:class="{
					'field__placeholder--hidden': value !== '',
					'field__placeholder--center': center
				}"
			>
				{{ ph }}
				<span
					v-if="isRequired"
					class="field__req"
					aria-label="Required"
				>*</span>
			</label>
			<div
				v-if="validation.max && limit"
				class="field__max"
			>
				{{ validation.max - value.length }}
			</div>
		</div>

		<transition name="fade">
			<small
				v-if="errorText || errors[0]"
				class="field__message"
				:class="{
					'field__message--center': center
				}"
				v-html="errorText || errors[0]"
			/>
		</transition>
	</ValidationProvider>
</template>

<script>
export default {
	name: "TextInput",
	model: {
		prop: "value",
		event: "input"
	},
	props: {
		type: { type: String, default: "text" },
		value: { type: [String, Number], required: true },
		validation: { type: [String, Object], default: "" },
		inputName: { type: String, required: true },
		labelText: { type: String, default: "" },
		vid: { type: String, default: "" },
		ph: { type: String, default: "" },
		inline: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		numeric: { type: Boolean, default: false },
		autocomplete: { type: String, default: "off" },
		pattern: { type: String, required: false },
		focused: { type: Boolean, default: false },
		selected: { type: Boolean, default: false },
		center: { type: Boolean, default: false },
		required: { type: Boolean, default: false },
		inputmode: { type: String, required: false },
		enterkeyhint: { type: String, required: false },
		limit: { type: Boolean, default: false },
		validationMode: { type: String, default: "aggressive" },
		errorText: { type: String, default: "" }
	},
	data: () => ({
		isValid: true
	}),
	computed: {
		isRequired() {
			return JSON.stringify(this.validation).indexOf("required") !== -1 || this.required;
		},
		computedMode() {
			if (this.type === "search" || this.type === "url" || this.type === "email") {
				return this.type;
			} else if (this.type === "number") {
				return "numeric";
			}
			return null;
		},
		keyHint() {
			return this.type === "search" ? "search" : this.enterkeyhint || "done";
		}
	},
	watch: {
		isValid(newVal) {
			this.$emit("onValidationStateChange", newVal);
		}
	},
	mounted() {
		const input = this.$refs[this.inputName];
		if (this.focused) input.focus();
		else if (this.selected) input.select();
		input.addEventListener("keypress", (event) => {
			if (event.keyCode === 13) this.onExecutePress(event);
		});
	},
	methods: {
		async onInput(event, promise) {
			this.$emit("input", event.target.value);
			const validationResult = await promise;
			this.isValid = validationResult.valid;
		},
		onExecutePress(e) {
			e.preventDefault();
			this.$emit("onExecutePress");
		}
	}
};
</script>

<style lang="sass">
.field
	$this: &
	--padding-x: 15px
	--field-height: 33px
	--offset-left: var(--padding-left, 15px)
	position: relative
	margin-bottom: 15px
	&--inline
		margin-bottom: 0
	&--numeric
		width: 100px
		margin-right: 15px
	&--limit input
		padding-right: 40px
	&__
		&label
			margin-bottom: 8px
			display: inline-block
			&[for]
				cursor: pointer
			a
				text-decoration: underline
				color: cl(base-theme)
				&:hover,
				&:focus
					text-decoration: none
		&req
			color: cl(accent-theme)
		&input-wrap
			width: 100%
			position: relative
			overflow-x: hidden
			border-radius: var(--borderRadius)
			border: 1px solid cl(light-dirty)
			&:focus-within
				border-color: cl(base-theme)
			&--
				&invalid
					border-color: cl(danger-theme)
		&eye
			display: flex
			align-items: center
			height: 100%
			width: calc(var(--padding-x) * 2)
			position: absolute
			right: 0
			top: 0
			z-index: 2
			cursor: pointer
			& + #{$this}__input
				padding-right: 35px
			&:hover,
			&:focus
				path
					stroke: cl(accent-theme)
		&input
			width: 100%
			height: 33px
			padding: 10px 0 10px var(--offset-left)
			position: relative
			z-index: 1
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button
				-webkit-appearance: none
				margin: 0
			&[type=number]
				-moz-appearance: textfield
			&:disabled
				background-color: cl(easy-light-blue)
				color: cl(_gray)
			&--
				&center
					text-align: center
					padding: 9px var(--padding-x) 10px
		&placeholder
			position: absolute
			top: 1px
			left: 1px
			right: 1px
			bottom: 1px
			padding: 7px var(--offset-left)
			white-space: nowrap
			overflow: hidden
			color: cl(_gray)
			z-index: 0
			transition: opacity .2s, transform .2s
			&--
				&hidden
					opacity: 0
					transform: translateX(30px)
				&center
					text-align: center
		&message
			position: absolute
			top: calc(100% + 2px)
			left: 0
			font-size: 10px
			color: cl(danger-theme)
			white-space: nowrap
			&--
				&center
					width: 100%
					text-align: center
		&max
			color: var(--light-dirty-color)
			position: absolute
			bottom: 10px
			right: 10px
			z-index: 3
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration
	-webkit-appearance: none
</style>
