export default {
	data: () => ({
		statisticsLoading: true
	}),
	methods: {
		async getCampaignsStatistics(campaigns, userId = this.$cu().id, useLoader = true) {
			if (campaigns.length) {
				try {
					if (useLoader) this.statisticsLoading = true;
					const stats = await this.$store.dispatch("getCampaignStatistics", {
						campaignsIds: campaigns.map((campaign) => campaign.id),
						userId: userId
					});
					campaigns.forEach((camp) => {
						const campStat = stats.find((stat) => stat.campaignId === camp.id);
						this.$set(camp, "statistics", campStat);
					});
				} finally {
					if (useLoader) this.statisticsLoading = false;
				}
			}
		}
	}
};
