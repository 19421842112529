export default {
	data: () => ({
		statisticsLoading: true
	}),
	methods: {
		async getLeadPacksStatistics(leadPacks) {
			try {
				this.statisticsLoading = true;
				const healthyPacks = leadPacks.filter((p) => p);
				if (healthyPacks.length) {
					const packsIdsArr = healthyPacks.map((pack) => pack.id);
					const stats = await this.$store.dispatch("getSearchLeadConfigStatistics", packsIdsArr);
					leadPacks.forEach((pack) => {
						const packStat = stats.find((stat) => stat.id === pack.id);
						this.$set(pack, "statistics", packStat);
					});
				}
			} finally {
				this.statisticsLoading = false;
			}
		}
	}
};
