import store from "@/js/store";

export async function verifyEmail(actionCode) {
	try {
		await store.dispatch("verifyEmail", actionCode);
	} finally {
		setTimeout(() => {
			window.location.href = `${location.protocol}//${location.host}${location.pathname}`;
		}, 1000);
	}
}
