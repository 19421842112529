import { render, staticRenderFns } from "./LeadsPackPreload.vue?vue&type=template&id=31b1d970"
var script = {}
import style0 from "./LeadsPackPreload.vue?vue&type=style&index=0&id=31b1d970&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports