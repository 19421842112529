<template>
	<div class="camp-stat-display">
		<div
			class="camp-stat-display__digit"
			:class="{ 'camp-stat-display__digit--blink': statisticsLoading }"
		>
			<component
				:is="isLink ? 'router-link' : 'span'"
				:to="isLink ? link : null"
				class="camp-stat-display__value"
			>
				<slot name="icon" />
				<span> <animate-number :to="value" /><template v-if="showPercent">%</template> </span>
			</component>

			<router-link
				v-if="count"
				:to="link"
			>
				<animate-number
					:to="count"
					class="camp-stat-display__count"
				/>
			</router-link>

			<transition name="to-top">
				<Icon
					v-if="showSearchArrows"
					v-tooltip="
						`There are leads that are currently being uploaded ${pack ? '' : 'to this campaign'}`
					"
					icon="uploading-arrows"
					w="16"
					h="16"
					class="camp-stat-display__uploading-arrows"
				/>
			</transition>
		</div>
		<span class="camp-stat-display__subtext"> <slot /> </span>
	</div>
</template>

<script>
export default {
	name: "CampaignStatisticsDisplaying",
	props: {
		campaign: { type: Object, required: true },
		pack: { type: Object, required: false },
		clickable: { type: Boolean, default: true },
		statisticsLoading: { type: Boolean, default: true },
		field: { type: String, required: true },
		countField: { type: String, required: false },
		filter: { type: String, required: false }
	},
	computed: {
		stat() {
			return this.pack ? this.pack.statistics : this.campaign.statistics;
		},
		isDraft() {
			return this.campaign.status === "DRAFT";
		},
		value() {
			return this.isDraft || !this.stat ? 0 : this.stat[this.field] || 0;
		},
		count() {
			return this.isDraft || !this.stat ? 0 : this.stat[this.countField] || 0;
		},
		isLink() {
			return (
				this.clickable && !this.statisticsLoading && !this.isDraft && this.value && !this.countField
			);
		},
		link() {
			let baseUrl = `/campaigns/${this.campaign.id}`;
			if (this.pack || this.filter || this.totalLeads) baseUrl += "/leads?";
			if (this.pack) baseUrl += `leadsList=${this.pack.id}&`;
			if (this.filter) baseUrl += `includeTypes=${this.filter}`;
			return baseUrl;
		},
		showPercent() {
			return this.field.includes("Rate");
		},
		totalLeads() {
			return this.field === "totalLeads" || this.field === "totalLeadsCount";
		},
		showSearchArrows() {
			return (
				this.totalLeads &&
				this.stat &&
				this.stat.searchActive &&
				this.campaign.status === "ACTIVE" &&
				!this.campaign.locked &&
				!this.$store.getters.sleepModeEnabled
			);
		}
	}
};
</script>

<style lang="sass">
.camp-stat-display
	[href]
		color: cl(base-theme)
	&__
		&digit
			display: inline-flex
			align-items: center
			gap: 5px
			&--blink
				animation: blinking 1s infinite
		&value
			display: inline-flex
			align-items: center
			gap: 5px
			font-size: 19px
			@media screen and (max-width: $desktop)
				font-size: 17px
		&count
			font-size: 15px
			margin-left: 5px
			margin-bottom: -2px
			@media screen and (max-width: $desktop)
				font-size: 14px
		&subtext
			display: block
			font-size: 12px
			color: cl(_gray)
			svg
				vertical-align: sub
		&uploading-arrows
			path
				animation: blinking 2s infinite
				&:nth-child(1)
					animation-delay: .666s
				&:nth-child(2)
					animation-delay: .333s
				&:nth-child(3)
					animation-delay: 0s
</style>
