<template>
	<div class="img-comp">
		<figure
			class="img-comp__wrap"
			:style="`--w: ${w}; --h: ${h}`"
		>
			<transition name="opacity">
				<img
					v-show="loaded"
					ref="img"
					class="img-comp__img"
					:src="require(`@/img/${src}`)"
					:alt="alt"
					@load="onLoad"
				>
			</transition>
		</figure>
	</div>
</template>

<script>
export default {
	name: "ImgComp",
	props: {
		src: { type: String, default: "" },
		alt: { type: String, default: "" },
		w: { type: String, default: "1" },
		h: { type: String, default: "1" }
	},
	data: () => ({
		loaded: false
	}),
	methods: {
		onLoad() {
			this.loaded = true;
		}
	}
};
</script>

<style lang="sass">
.img-comp
	width: 100%
	&__
		&wrap
			padding-bottom: calc((var(--h) / var(--w)) * 100%)
			height: 0
			overflow: hidden
			display: block
			position: relative
		&img
			+centerer
			object-fit: cover
</style>
