import scrubber from "./scrubber";

export default {
	modules: {
		scrubber
	},
	actions: {
		async getSchedule(store) {
			try {
				const res = await window.request("GET", "/schedule");

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async updateSchedule(store, payload) {
			try {
				const res = await window.request("PUT", "/schedule", payload);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getLimits(store) {
			try {
				const res = await window.request("GET", "/processinglimits/current");

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async updateLimits(store, payload) {
			try {
				const res = await window.request("PUT", "/processinglimits", payload);
				store.commit("setActivityControlStatus", res.data.activityControlEnabled);
				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getTransactions(_, payload) {
			const res = await window.request("GET", "/v1/shared/billings/transactions", payload);

			return res.data;
		}
	}
};
