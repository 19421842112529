<template>
	<ValidationObserver>
		<div class="leads-adding">
			<TextInput
				v-model="name"
				input-name="leadsPackName"
				label-text="Give your list a name"
				ph="New York, 2-3 level connections, retail, CEOs and founders"
				enterkeyhint="next"
				validation="max:50"
			/>
			<p class="tac">
				<b>How would you like to add leads?</b>
			</p>
			<br>
			<Tabs @change="tabChangeHandler">
				<Tab
					v-if="showLinkedInSearchTab"
					name="LinkedIn Search"
				>
					<LISearch
						@change="saveLeadPackOptions"
						@searchStatusChange="disabledStatusChangeHandler"
					/>
				</Tab>
				<Tab name="Paste profile URLs">
					<ProfileURLs
						@change="saveLeadPackOptions"
						@changeSavingAbility="disabledStatusChangeHandler"
					/>
				</Tab>
				<Tab name="Upload CSV file">
					<LeadsFromCSV
						@change="saveLeadPackOptions"
						@changeSavingAbility="disabledStatusChangeHandler"
					/>
				</Tab>
			</Tabs>
		</div>

		<div class="popup__footer popup__footer--between">
			<AsyncButton
				:async-f="addLeadsPack"
				:disabled="disabled || !leadsPackOptions"
				class="btn btn--accent"
			>
				Create a list
			</AsyncButton>
		</div>

		<transition name="fade">
			<WrongContract
				v-if="errorPopup === 'SEARCH_WRONG_CONTRACT'"
				key="1"
				@change="closePopupAndCreateList"
				@close="closeErrorPopup"
			/>
			<SearchGenericError
				v-else-if="errorPopup === 'SEARCH_GENERIC_ERROR'"
				key="2"
				:search-url="leadsPackOptions.searchUrl"
				@close="closeErrorPopup"
			/>
			<SearchSessionIsTaken
				v-else-if="errorPopup === 'SEARCH_SESSION_IS_TAKEN'"
				key="3"
				@sessionsCleared="closePopupAndCreateList"
				@close="closeErrorPopup"
			/>
		</transition>
	</ValidationObserver>
</template>

<script>
import Tabs from "@/js/components/Tabs/Tabs";
import Tab from "@/js/components/Tabs/Tab";
import LISearch from "@/js/components/Campaign/Audience/LinkedInSearch/LISearch";
import searchLeadsType from "@/js/mixins/searchLeadsType.mixin";

export default {
	name: "LeadsAdding",
	components: {
		Tabs,
		Tab,
		LISearch,
		ProfileURLs: () => window.compLoader(import("@/js/components/Campaign/Audience/ProfileURLs")),
		LeadsFromCSV: () => window.compLoader(import("@/js/components/Campaign/Audience/LeadsFromCSV")),
		WrongContract: () => window.compLoader(import("@/js/components/ContractPopups/WrongContract")),
		SearchGenericError: () =>
			window.compLoader(import("@/js/components/Campaign/Audience/ErrorPopups/SearchGenericError")),
		SearchSessionIsTaken: () =>
			window.compLoader(
				import("@/js/components/Campaign/Audience/ErrorPopups/SearchSessionIsTaken")
			)
	},
	mixins: [searchLeadsType],
	props: {
		campaignId: { type: Number, required: true }
	},
	data: () => ({
		name: "",
		leadsPackOptions: null,
		disabled: true,
		errorPopup: ""
	}),
	computed: {
		showLinkedInSearchTab() {
			return !this.$cu().email.endsWith("@sap.com"); // SAP asks to hide "LinkedIn Search" tab for their emails
		}
	},
	methods: {
		saveLeadPackOptions(options) {
			this.leadsPackOptions = options;
		},
		async addLeadsPack() {
			try {
				const packName = this.name || this.searchType(this.leadsPackOptions);
				const res = await this.$store.dispatch("createSearchLeadsConfigItem", {
					...this.leadsPackOptions,
					name: packName,
					campaignId: this.campaignId
				});
				this.$emit("addingLeads", res);
			} catch (err) {
				this.errorPopup = err.response.data.message;
				throw err;
			}
		},
		tabChangeHandler() {
			this.leadsPackOptions = null;
		},
		disabledStatusChangeHandler(val) {
			this.disabled = val;
		},
		closeErrorPopup() {
			this.errorPopup = "";
		},
		closePopupAndCreateList() {
			this.closeErrorPopup();
			this.addLeadsPack();
		}
	}
};
</script>

<style lang="sass">
.leads-count
	overflow: hidden
	max-height: 86px
	height: 86px

.leads-adding
	&__
		&parsed
			font-size: 14px
			overflow: hidden
			line-height: 22px
			max-height: 22px
			height: 22px
		&overlength
			font-size: 12px
			color: cl(danger-theme)
			overflow: hidden
			max-height: 42px
			height: 42px
</style>
