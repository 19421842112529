export default {
	actions: {
		async getFilters(store) {
			try {
				const res = await window.request("GET", "/events/filters");

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async createFilter(store, payload) {
			try {
				const res = await window.request("POST", "/events/filters", payload);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async editFilter(store, { payload, id }) {
			try {
				const res = await window.request("PUT", `/events/filters/${id}`, payload);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async removeFilter(store, id) {
			try {
				await window.request("DELETE", `/events/filters/${id}`);
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		}
	}
};
