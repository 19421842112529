import { formattingParams } from "@/js/helpers/paramsFormatter";

export default {
	state: {
		contracts: []
	},
	getters: {
		contracts: (s) => s.contracts
	},
	mutations: {
		setContracts(state, arr) {
			state.contracts = arr;
		}
	},
	actions: {
		async getContracts(store, params) {
			const res = await window.request("GET", "/v1/contracts", params);
			store.commit("setContracts", res.data);
		},
		async changeContract(store, params) {
			const url = `/userdetails/authentication/?${formattingParams(params)}`;
			const res = await window.request("PUT", url);
			store.commit("setCurrentUserField", {
				chosenContract: res.data.contract
			});
		},
		async signOutOtherSessions(store) {
			const config = { headers: { "X-User-Id": store.getters.userInfo.uid } };
			await window.request("DELETE", "/v1/contracts/othersessions", config);
		},
	}
};
