export default {
	state: {
		toast: null
	},
	getters: {
		toast: (s) => s.toast
	},
	mutations: {
		setMessage(state, msg) {
			state.toast = msg;
		}
	}
};
