export default {
	state: {
		notifications: null
	},
	getters: {
		notifications: (s) => (s.notifications ? s.notifications.content : [])
	},
	mutations: {
		setNotifications(state, payload) {
			state.notifications = payload;
		},
		removeNotificationFormList(state, index) {
			state.notifications.content.splice(index, 1);
		}
	},
	actions: {
		async getNotifications(store) {
			try {
				const res = await window.request("GET", "/v1/shared/notifications/current");

				store.commit("setNotifications", res.data);
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async closeNotification(store, id) {
			try {
				await window.request("PATCH", `/v1/shared/notifications/${id}`, {
					closed: true
				});
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async changeSequenceRestriction(store, { id, payload }) {
			try {
				await window.request("PATCH", `v1/sequencerestrictions/${id}`, payload);
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		}
	}
};
