<template>
	<div
		v-if="statisticsLoading"
		class="campaign-status-placeholder"
	/>
	<div
		v-else
		v-tooltip="campaign.locked ? 'Upgrade to a higher plan to unlock this campaign' : null"
		class="campaign-status-trigger"
	>
		<label
			class="campaign-status-trigger__text"
			:class="{
				'campaign-status-trigger__text--active': isActive,
				'campaign-status-trigger__text--disabled': disabled
			}"
			:for="`isActive${campaign.id}`"
		>
			{{ status === "DRAFT" ? "Draft" : isActive ? "Active" : "Not active" }}
		</label>
		<Trigger
			v-model="isActive"
			:disabled="disabled"
			:check-name="`isActive${campaign.id}`"
			@change="emitCampaignStatus"
		/>
	</div>
</template>

<script>
import Trigger from "@/js/components/Form/Trigger";
export default {
	name: "CampaignStatus",
	components: { Trigger },
	props: {
		campaign: { type: Object, required: true },
		disabled: { type: Boolean, default: false },
		statisticsLoading: { type: Boolean, default: false }
	},
	data: () => ({
		isActive: false
	}),
	computed: {
		status() {
			return this.campaign.status;
		}
	},
	watch: {
		status(newVal) {
			this.isActive = newVal === "ACTIVE";
		}
	},
	mounted() {
		this.isActive = this.status === "ACTIVE" && !this.campaign.locked;
	},
	methods: {
		emitCampaignStatus(val) {
			this.$emit("changeCampaignStatus", val);
		}
	}
};
</script>

<style lang="sass">
.campaign-status-placeholder
	margin: 0 auto
	width: 80%
	height: 25px
	border-radius: 5px
	background: linear-gradient(to right, cl(easy-blue) 0%, cl(easy-light-blue) 100%)
	animation: blinking 1s infinite

.campaign-status-trigger
	display: flex
	flex-direction: column
	align-items: center
	gap: 5px
	&__
		&text
			font-size: 13px
			color: cl(_gray)
			text-align: center
			&--
				&active
					color: cl(b)
				&disabled
					pointer-events: none
			@media screen and (max-width: $laptop)
				font-size: 13px
</style>
