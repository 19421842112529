import { failedUserStatus } from "@/js/helpers/failedUserStatus";
import { isObject } from "@/js/helpers/utils";
import { setStatusInStorage } from "@/js/helpers/storageStatus";

export default {
	state: {
		currentUser: null,
		userStatus: "",
		reminders: []
	},
	getters: {
		currentUser: (s) => s.currentUser,
		userStatus: (s) => (s.currentUser ? s.currentUser.status : s.userStatus),
		failureStatus: (_, g) => failedUserStatus(g.userStatus),
		remindersArr: (s) => s.reminders
	},
	mutations: {
		setCurrentUserField(state, payload) {
			Object.keys(payload).forEach((key) => {
				state.currentUser[key] = payload[key];
			});
		},
		setCurrentUser(state, obj) {
			state.currentUser = obj;
			setStatusInStorage(state.currentUser.status);
		},
		clearCurrentUser(state) {
			state.currentUser = null;
		},
		setActivityControlStatus(state, val) {
			state.currentUser.activityControlEnabled = val;
		},
		setTimezone(state, val) {
			state.currentUser.zone = val;
		},
		changeCurrentUserStatus(state, val) {
			if (state.currentUser) {
				state.currentUser.status = val;
			}
			state.userStatus = val;
			setStatusInStorage(val);
		},
		setReminders(state, val) {
			state.reminders = val;
		},
		setUpdatedReminder(state, val) {
			const reminderToChangeIndex = state.reminders.findIndex(
				(r) => r.reminderType === val.reminderType
			);
			if (reminderToChangeIndex === -1) {
				state.reminders.push(val);
			} else {
				state.reminders.splice(reminderToChangeIndex, 1, val);
			}
		}
	},
	actions: {
		async getCurrentUserData(store) {
			if (!store.getters.userInfo) store.commit("setUser");
			const user = await window.request("GET", "/userdetails/current");
			store.commit("setCurrentUser", user.data);
		},
		async editCurrentUserData(store, payload) {
			const user = await window.request("PATCH", "/userdetails/current", payload);
			store.commit("setCurrentUser", user.data);
		},
		async sendCurrentUserData(store, payload) {
			if (!store.getters.userInfo) store.commit("setUser");
			const config = { headers: { "X-User-Id": store.getters.userInfo.uid } };
			const user = await window.request("POST", "/userdetails", payload, config);
			store.commit("setCurrentUser", user.data);
		},
		async sendLIVerify(store, payload) {
			try {
				const config = { headers: { "X-User-Id": payload.userId } };
				const user = await window.request("POST", "/userdetails/verify", payload, config);
				store.commit("setCurrentUser", user.data);
			} catch (err) {
				const error = err.response.data;

				if (error.message === "WRONG_VERIFICATION_CODE") {
					store.commit("setMessage", "Wrong verification code");
				} else if (!failedUserStatus(error.message)) {
					store.commit("setMessage", error);
				}
				throw err;
			}
		},
		async sendVerificationCode(store) {
			try {
				await window.request("POST", "/userdetails/verification/code");
			} catch (err) {
				const error = err.response.data;

				if (error.status === 409) {
					store.commit("setMessage", error);
				}
				throw err;
			}
		},
		async getUserDetailsById(_, payload) {
			let userId, isFull;
			if (isObject(payload)) {
				userId = payload.userId;
				isFull = payload.isFull;
			} else {
				userId = payload;
				isFull = false;
			}
			const config = { headers: { "X-User-Id": userId } };
			const user = await window.request("GET", `/userdetails/${userId}`, { isFull }, config);

			return user.data;
		},
		async changeUserDetails(_, { id, payload }) {
			const config = { headers: { "X-User-Id": id } };
			const res = await window.request("PUT", `/userdetails/${id}`, payload, config);

			return res.data;
		},
		async getEmailUsingInfo(store, email) {
			const res = await window.request("GET", `/userdetails/verify/${email}`);
			const emailAlreadyInUse = res.data;
			if (emailAlreadyInUse) {
				store.commit("setMessage", `Email <b>${email}</b> is already in use by another account`);
			}
			return emailAlreadyInUse;
		},
		async changeUserEmail(store, payload) {
			const user = await window.request("PATCH", "/userdetails/email", payload);
			if (user.data) store.commit("setCurrentUser", user.data);
		},
		async getAuthorizationUrl(store) {
			try {
				const res = await window.request("GET", "/v1/auth");
				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async oAuthCallback(store, code) {
			try {
				await window.request("GET", "/v1/auth/exchange", { code });
			} catch (err) {
				const PERMISSION_ERROR_TEXT = "token does not have the necessary permissions"
				if (err.response.data.status === 403 && err.response.data.message.includes(PERMISSION_ERROR_TEXT)) {
					store.commit("setMessage", "Your Gmail account has not been connected. Please grant Dripify permission to send emails on your behalf during the integration setup");
				} else {
					store.commit("setMessage", err.response.data);
				}
				throw err;
			}
		},
		async removeToken(store) {
			try {
				await window.request("DELETE", "/v1/auth");
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getReminders(store) {
			const res = await window.request("GET", "/v1/shared/versionsync/current");
			store.commit("setReminders", res.data);

			return res.data;
		},
		async changeReminderStatus(store, payload) {
			const res = await window.request("POST", "/v1/shared/versionsync", payload);
			store.commit("setUpdatedReminder", res.data);
			return res.data;
		}
	}
};
