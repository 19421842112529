import axios from "axios";
import store from "@/js/store/index";
import router from "@/js/router";
import { failedUserStatus } from "@/js/helpers/failedUserStatus";

export default function setup() {
	axios.interceptors.request.use(requestIntersept, (err) => Promise.reject(err));
	axios.interceptors.response.use((response) => response, responseErrorIntersept);
}

async function requestIntersept(config) {
	const sideAccId = store.getters.sideAccountId;

	if (allowRequest(config.url)) {
		let tokenResult;
		tokenResult = await store.dispatch("getTokenResult");
		if (!tokenResult.claims.linkedin_id) {
			tokenResult = await store.dispatch("getTokenResult", true);
		}

		config.headers.common.Authorization = `Bearer ${tokenResult.token}`;

		if (sideAccId) config.headers["X-SIGNED-USER-ID"] = sideAccId;
		return config;
	}
	return;
}

function responseErrorIntersept(error) {
	const resp = error.response;
	if (resp) {
		const data = resp.data;
		checkIfBadStatusResponse(data);
	}
	return Promise.reject(error);
}

function allowRequest(requestedUrl) {
	const allowedUrlsOnPinChallanged = ["/userdetails/verify", "/userdetails/verification/code"];
	const pinChallanged = store.getters.userStatus === "PIN_CHALLENGED";
	const configUrlAllowedOnPinChallanged = allowedUrlsOnPinChallanged.some((url) =>
		requestedUrl.includes(url)
	);

	return !pinChallanged || configUrlAllowedOnPinChallanged;
}

function checkIfBadStatusResponse(data) {
	if (data && data.status === 401) {
		const statusForRedirect = failedUserStatus(data.message);
		const twoFaChallanged = data.message.includes("TWO_FA");
		const pinChallanged = data.message === "PIN_CHALLENGED";
		const captcha = data.message === "CAPTCHA_IN_PROGRESS";

		if (statusForRedirect || twoFaChallanged || pinChallanged || captcha) {
			store.commit("changeCurrentUserStatus", data.message);
			if (statusForRedirect) router.push("/enter-linkedin");
		}
	}
}
