import { formattingParams } from "@/js/helpers/paramsFormatter";
import { BEST_PLAN_ID } from "@/js/helpers/constants/constants";
import { getVisitorId } from "@/js/helpers/affiliate";
import { diffDates } from "@/js/helpers/utils";
export default {
	state: {
		billing: null
	},
	getters: {
		billing: (s) => s.billing,
		subscription: (s) => s.billing && s.billing.subscription,
		daysUntilTrialEnds: (s) => (s.billing ? diffDates(s.billing.trialEndsAt, Date.now()) : 100),
		isTrial: (_, g) => g.daysUntilTrialEnds <= 7 && g.daysUntilTrialEnds > 0 && !g.subscription,
		freeTrialEnds: (_, g) => g.billing && g.billing.trialEndsAt <= Date.now() && !g.subscription,
		subscriptionStatus: (_, g) => g.subscription && g.subscription.status,
		subscriptionDeactivated: (_, g) => g.subscriptionStatus === "deactivated",
		subscriptionPaused: (_, g) => g.subscriptionStatus === "paused",
		subscriptionEnded: (_, g) => g.billing && (g.freeTrialEnds || g.subscriptionDeactivated),
		myPlanId: (_, g) => (g.subscription ? g.subscription.priceId : BEST_PLAN_ID),
		subscriptionBuyerIsCurrentUser: (s, g) =>
			g.currentUser && g.subscription && g.subscription.buyerId === g.currentUser.id,
		allowToUpgrade(_, g) {
			const notBestPlanBoughtByMyself =
				g.myPlanId !== BEST_PLAN_ID && g.subscriptionBuyerIsCurrentUser;
			const badStatus =
				g.subscriptionStatus === "canceled" || g.subscriptionStatus === "deactivated";
			return (
				g.billing &&
				(!g.subscription || notBestPlanBoughtByMyself || badStatus) &&
				!g.scheduledForPause
			);
		},
		scheduledForPause: (_, g) => g.subscription && g.subscription.pausedAt,
		newBasicPlan: (_, g) => g.myPlanId.includes("basic") && g.currentUser.apiVersion > 1
	},
	mutations: {
		setBillingData(state, data) {
			state.billing = data;
		}
	},
	actions: {
		async getCurrentBillingResponse(store) {
			const res = await window.request("GET", "/v1/shared/billings/current");
			store.commit("setBillingData", res.data);
		},
		async getPrices(store) {
			try {
				const res = await window.request("GET", "/v1/shared/billings/prices");
				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async activateSubscription(store, orderId) {
			try {
				await window.request("POST", "/v1/shared/billings/subscriptions", {
					orderId
				});
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},

		async getUsersPaymentDetails(store, id) {
			try {
				const res = await window.request("GET", "/v1/shared/billings/payment/details", {
					userDetailsId: id
				});

				return res.data;
			} catch (err) {
				if (err.response.data.status !== 404) {
					store.commit("setMessage", err.response.data);
				}
				throw err;
			}
		},
		async getBillingsByIds(store, payload) {
			try {
				const res = await window.request(
					"GET",
					"/v1/shared/billings",
					formattingParams(payload, true)
				);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async createSubscription(_, payload) {
			await window.request("POST", "/v1/shared/billings/subscriptions/create", {
				...payload,
				papCookie: getVisitorId()
			});
		},
		async updateSubscription(_, payload) {
			await window.request("PUT", "/v1/shared/billings/subscriptions", payload);
		},
		async getMyOtherSubscriptions() {
			const res = await window.request("GET", "/v1/shared/billings/subscriptions");

			return res.data;
		},
		async pauseSubscription(_, { payload, subscriptionId }) {
			const url = `/v1/shared/billings/subscriptions/${subscriptionId}/pause`;
			const res = await window.request("PUT", url, payload);

			return res.data;
		},
		async resumeSubscription(_, { payload, subscriptionId }) {
			const url = `/v1/shared/billings/subscriptions/${subscriptionId}/resume/`;
			const res = await window.request("PUT", url, payload);

			return res.data;
		},
		async cancelSubscription(_, { userId, payload }) {
			const url = `/v1/shared/billings/subscriptions/${userId}/?${formattingParams(payload)}`;
			await window.request("DELETE", url);
		},
		async verifyPurchase(store, payload) {
			try {
				const res = await window.request(
					"POST",
					"/v1/shared/billings/subscriptions/verify",
					payload
				);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getAccountBillingEmail(store) {
			try {
				const res = await window.request("GET", "/v1/shared/billings/payment/details/email");

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async isPaymentDetailsRemovable(store) {
			try {
				const res = await window.request("GET", "/v1/shared/billings/payment/details/removable");

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async removePaymentDetails(store) {
			try {
				const res = await window.request("DELETE", "/v1/shared/billings/payment/details");

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		}
	}
};
