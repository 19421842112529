import store from "@/js/store";
import { sendPasswordResetEmail } from "@/js/helpers/sendPasswordResetEmail";
import router from "@/js/router";

export async function emailRecovery(actionCode) {
	const restoredEmail = await store.dispatch("emailRecovery", actionCode);
	await sendPasswordResetEmail(restoredEmail, "Your email has been recovered");
	await store.dispatch("logout");
	router.push("/");
}
