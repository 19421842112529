<template>
	<section
		class="la-search"
		aria-label="LinkedIn Search panel"
	>
		<SearchValidator
			v-model="link"
			@statusChange="onSearchValidated"
		/>
		<div
			v-if="$store.getters.signedInToTeammate"
			class="formatted-text"
		>
			<p>
				<b>Important!</b> Since the leads will be transferred from the search page of
				{{ userFullName($cu().linkedinCredentialsResponse) }}'s LinkedIn account, It's recommended
				to filter profiles in the LinkedIn Search using {{ firstName }}'s LinkedIn account,
				otherwise, the results might not be accurate.
			</p>
			<p>
				If you would like to make searches from your LinkedIn account and transfer profiles to
				{{ firstName }}'s campaign from there,
				<a
					href="http://help.dripify.io/en/articles/5612509-how-to-add-leads-from-sales-navigator-if-you-have-a-free-linkedin-account"
					target="_blank"
				>here is a quick guide</a>
			</p>
			<br>
		</div>
		<transition name="slide">
			<div
				v-if="validSearch"
				class="leads-count-found"
			>
				LinkedIn profiles found:
				<b>{{ numberWithSpaces(maxSearchValue)
				}}<template v-if="+totalLeadsFound === 1000">+</template></b>
				<p
					v-if="validationResponse.startFrom"
					class="leads-count-found__not-first"
				>
					The link you've pasted does not come from the first page of search results, therefore all
					leads from previous pages will be skipped
				</p>
			</div>
		</transition>

		<transition name="slide">
			<div
				v-if="totalLeadsFound"
				class="leads-count-settings"
			>
				<label
					class="field__label"
					for="leadsCount"
				>
					How many users you would like to add to this list?
					<span
						aria-label="Required"
						class="field__req"
					>*</span>
					<span class="small"> (max: {{ maxSearchValue }})</span>
				</label>
				<div class="flex">
					<TextInput
						v-model="leadsCount"
						input-name="leadsCount"
						:validation="{
							numeric: true,
							required: true,
							max_value: maxSearchValue,
							min_value: 1
						}"
						:disabled="allUsers"
						inputmode="numeric"
						numeric
						inline
					/>
					<Checkbox
						v-model="allUsers"
						check-name="allUsers"
					>
						max
					</Checkbox>
				</div>
			</div>
		</transition>
	</section>
</template>

<script>
import SearchValidator from "@/js/components/Campaign/Audience/LinkedInSearch/SearchValidator";
import userName from "@/js/mixins/userName.mixin";
import { numberWithSpaces } from "@/js/helpers/utils";

export default {
	name: "LISearch",
	components: { SearchValidator },
	mixins: [userName],
	data: () => ({
		link: "",
		validationResponse: false,
		leadsCount: 100,
		allUsers: false
	}),
	computed: {
		leadsNumberToCollect() {
			return this.allUsers ? +this.maxSearchValue : +this.leadsCount;
		},
		options() {
			return {
				searchUrl: this.link,
				leadsNumberToCollect: this.leadsNumberToCollect,
				searchType: "DEFAULT_PEOPLE"
			};
		},
		totalLeadsFound() {
			return this.validationResponse.totalLeadsCount;
		},
		validSearch() {
			return Object.prototype.hasOwnProperty.call(this.validationResponse, "totalLeadsCount");
		},
		firstName() {
			return this.userFirstName(this.$cu().linkedinCredentialsResponse);
		},
		isSalesNav() {
			return this.link.includes("sales/search/people") || this.link.includes("sales/lists/people");
		},
		maxSearchValue() {
			let MAX_VALUE = this.isSalesNav ? 2475 : 2500;

			return this.totalLeadsFound >= MAX_VALUE ? MAX_VALUE : this.totalLeadsFound;
		}
	},
	watch: {
		allUsers(newValue) {
			if (newValue) this.leadsCount = this.maxSearchValue;
		},
		leadsCount() {
			this.onSearchConfigChanged();
		},
		options: {
			handler() {
				this.$emit("change", {
					searchLeadIds: [],
					searchOption: "LINKEDIN_SEARCH",
					...this.options
				});
			},
			deep: true
		}
	},
	methods: {
		numberWithSpaces,
		onSearchValidated(val) {
			this.validationResponse = val;
			if (this.validSearch) {
				this.leadsCount = this.totalLeadsFound < 100 ? this.totalLeadsFound : 100;
			}
			this.onSearchConfigChanged();
		},
		onSearchConfigChanged() {
			this.$emit(
				"searchStatusChange",
				!this.totalLeadsFound || !+this.leadsCount || this.totalLeadsFound < this.leadsCount
			);
		}
	}
};
</script>

<style lang="sass">
.leads-count-found
	overflow: hidden
	max-height: 75px
	padding-bottom: 10px
	&__
		&not-first
			font-size: 12px
			margin-top: 5px
			color: cl(dark-gray)

.leads-count-settings
	overflow: hidden
	max-height: 86px
	height: 86px

.leads-adding
	&__
		&parsed
			font-size: 14px
			overflow: hidden
			line-height: 22px
			max-height: 22px
			height: 22px
		&overlength
			font-size: 12px
			color: cl(danger-theme)
			overflow: hidden
			max-height: 42px
			height: 42px
</style>
