<template>
	<div
		ref="popup"
		class="modal"
		:class="{
			'modal--can-close': canClose
		}"
		role="dialog"
		@mousedown="clickForClose"
		@keyup.esc="closeOnEsc"
	>
		<div class="modal__inner">
			<slot />

			<button
				v-if="cross"
				class="modal__cross"
				title="Close"
				aria-label="Close modal"
				@click.prevent="closePopup"
			>
				<Icon
					icon="cross"
					stroke="var(--accent-theme-color)"
					w="10"
					h="10"
				/>
			</button>
		</div>
	</div>
</template>

<script>
import PopupMixin from "@/js/mixins/popup.mixin";

export default {
	name: "CleanPopup",
	mixins: [PopupMixin],
	props: {
		cross: { type: Boolean, default: false },
		canClose: { type: Boolean, default: false }
	},
	methods: {
		clickForClose(event) {
			if (this.canClose) {
				if (event.target === this.$refs.popup && event.type === "mousedown") this.closePopup();
			}
		},
		closeOnEsc() {
			if (this.canClose) this.closePopup();
		}
	}
};
</script>

<style lang="sass">
.modal
	position: fixed
	width: 100%
	height: 100%
	left: 0
	top: 0
	z-index: 1002
	overflow: hidden auto
	background: cl(main-more-contrast-theme, .8)
	padding: calc(var(--header-h) + 10px) 15px 20px
	text-align: center
	--bdrs: 7px
	&--
		&can-close
			cursor: pointer
	&::before
		content: ''
		display: inline-block
		height: 100%
		vertical-align: middle
	&__
		&inner
			text-align: left
			display: inline-block
			vertical-align: middle
			position: relative
			background: cl(w)
			border-radius: var(--bdrs)
			cursor: auto
			max-width: 1160px
			&--
				&small
					max-width: 470px
		&cross
			position: absolute
			right: 0
			top: 0
			width: 30px
			height: 30px
			+flex(center, center)
			border-radius: 0 7px 0 0
			&:hover
				opacity: 0.6
</style>
