export default {
	actions: {
		async getUserUndesirableWords() {
			const res = await window.request("GET", "/v1/shared/leads/undesirable-words");

			return res.data;
		},
		async updateUndesirableWords(store, arr) {
			try {
				const res = await window.request("POST", "/v1/shared/leads/undesirable-words", { personNameWords: arr });

				return res.data;
			} catch (err) {
				const errData = err.response.data;
				if (errData.status === 422 && errData.message.includes("Limit is 1024 characters")) {
					store.commit("setMessage", "The maximum character limit of 1024 for the scrubber has been reached.<br>Please remove some words to make space for adding a new entry");
				} else {
					store.commit("setMessage", errData);
				}
				throw err;
			}
		}
	}
};
