<template>
	<div
		class="spinner"
		:class="{ 'spinner--bg': bg }"
		:style="{ '--spinner-size': `${size}px` }"
	/>
</template>

<script>
export default {
	name: "Spinner",
	props: {
		bg: { type: Boolean, default: false },
		size: { type: [String, Number], default: 25 }
	}
};
</script>

<style lang="sass">
.spinner
	position: absolute
	z-index: 2
	height: 100%
	width: 100%
	overflow: visible
	top: 0
	left: 0
	display: flex
	align-items: center
	justify-content: center
	cursor: progress
	&--bg
		background-color: cl(_gray, .2)
	&::after
		content: ''
		display: block
		width: var(--spinner-size, 25px)
		height: var(--spinner-size, 25px)
		border-radius: 50%
		background: url(/assets/loader.png) center no-repeat
		background-size: contain
		animation: spinner 1s infinite linear
		@media screen and (max-width: $tablet)
			width: 20px
			height: 20px

@keyframes spinner
	0%
		transform: rotate(0deg)
	100%
		transform: rotate(360deg)
</style>
