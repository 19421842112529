export default {
	actions: {
		async getUserPreferences(store, userId) {
			try {
				if (!userId) {
					userId = store.getters.userInfo.uid;
				}

				const res = await window.request("GET", `/userpreferences/${userId}`);

				return res.data;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
			}
		},
		async changeUserPreferences(store, { userId, payload }) {
			try {
				await window.request("POST", `/userpreferences/update/${userId}`, payload);
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		}
	}
};
