import store from "@/js/store/index";

export default {
	writeTestAcc() {
		if (window.location.search.includes("testAccount")) {
			localStorage.setItem("testAccount", true);
		}
	},
	deleteTestAcc() {
		localStorage.removeItem("testAccount");
	},
	testAccount() {
		return localStorage.getItem("testAccount");
	},
	adminStatus() {
		return store.getters.adminStatus;
	},
	isAdmin() {
		return this.adminStatus() === "ADMIN";
	},
	isProxyManager() {
		return this.adminStatus() === "PROXY_MANAGER";
	},
	devMode() {
		return process.env.NODE_ENV === "development";
	},
	hasAdminPreferences() {
		return this.isAdmin() || this.testAccount() || this.devMode();
	}
};
