<template>
	<div>
		<Audience
			:campaign="campaign"
			@packsCountChanged="onPacksCountChanged"
		/>
		<template v-if="packsLength">
			<hr class="card__hr card__hr--top">
			<div class="flex-center">
				<button
					id="step1Next"
					class="btn btn--accent"
					@click="saveAndContinue"
				>
					Next
				</button>
			</div>
		</template>
	</div>
</template>

<script>
import Audience from "@/js/components/Campaign/Audience/Audience";

export default {
	name: "CampaingStep1",
	metaInfo: {
		title: "Create a campaign 1/3"
	},
	components: { Audience },
	props: {
		campaign: { type: Object, required: true }
	},
	data: () => ({
		packsLength: 0
	}),
	methods: {
		onPacksCountChanged(val) {
			this.packsLength = val;
			this.$emit("packsCountChanged", this.packsLength);
		},
		saveAndContinue() {
			this.$emit("changeStep", "next");
		}
	}
};
</script>
