export default {
	actions: {
		async sendMessageToSupport(store, payload) {
			try {
				const config = { headers: { "Content-Type": "multipart/form-data" } };
				await window.request("POST", "/support/tickets", payload, config);
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		}
	}
};
