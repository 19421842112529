export default {
	LI_SEARCH_TEXT: "LinkedIn Search",
	PROFILES_URLS_TEXT: "Profile URLs",
	methods: {
		isLISearch(pack) {
			return pack.searchOption === "LINKEDIN_SEARCH";
		},
		searchType(pack) {
			return this.isLISearch(pack)
				? this.$options.LI_SEARCH_TEXT
				: this.$options.PROFILES_URLS_TEXT;
		},
		searchLeadsPackName(pack) {
			return pack ? pack.name || this.searchType(pack) : "";
		}
	}
};
