import "wicg-inert";

export default {
	interactiveElements: '[tabindex="0"], input, textarea, button, a',
	notInertElementsSelector: ".popup, .modal, .header, .easy-header",
	mounted() {
		this.disableUnsuedElements();

		setTimeout(() => {
			const { popup } = this.$refs;
			const interactiveElement = popup.querySelector(this.$options.interactiveElements);

			if (interactiveElement) interactiveElement.focus();
		}, 100);
		document.body.classList.add("overflow");
	},
	beforeDestroy() {
		document.body.classList.remove("overflow");
		this.changeInertOfAll(false);
	},
	methods: {
		closePopup() {
			this.$emit("close");
		},
		disableUnsuedElements() {
			const noInertElementsParents = document.querySelectorAll(
				this.$options.notInertElementsSelector
			);
			this.changeInertOfAll(true);
			noInertElementsParents.forEach((el) => {
				el.inert = false;
				this.changeInertOfAll(false, el);
			});
		},
		changeInertOfAll(val, scope = document) {
			const els = scope.querySelectorAll(this.$options.interactiveElements);
			els.forEach((el) => (el.inert = val));
		}
	}
};
