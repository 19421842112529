export default {
	state: {
		statData: {}
	},
	getters: {
		statData: (s) => s.statData
	},
	mutations: {
		setStatData(state, payload) {
			state.statData = payload;
		}
	},
	actions: {
		async getEvents(store, payload) {
			try {
				const res = await window.request("GET", "/events", payload);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getStatistics(store, { payload, userId }) {
			try {
				const config = { headers: { "X-User-Id": userId } };
				const res = await window.request("GET", "/events/statistics", payload, config);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		}
	}
};
