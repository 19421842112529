export default {
	state: {
		unreadCount: 0
	},
	getters: {
		unreadCount: (s) => s.unreadCount
	},
	mutations: {
		setUnreadMessagesCount(state, payload) {
			state.unreadCount = payload;
		}
	},
	actions: {
		async getMessagingConversations(store, params) {
			try {
				const res = await window.request("GET", "/messaging/conversations", params);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getConversationsByLeadInUserId(store, leadInUserId) {
			try {
				const res = await window.request(
					"GET",
					`/messaging/conversations/leadInUserIds/${leadInUserId}`
				);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getConversation(_, conversationId) {
			const res = await window.request("GET", `/messaging/conversations/${conversationId}`);

			return res.data;
		},
		async getConversationEvents(store, { conversationId, params }) {
			try {
				const res = await window.request(
					"GET",
					`/messaging/conversations/${conversationId}/events`,
					params
				);

				return res.data;
			} catch (err) {
				if (err.response.data.status === 410) {
					store.commit("setMessage", "The conversation has been deleted in Linkedin!");
				} else {
					store.commit("setMessage", err.response.data);
				}
				throw err;
			}
		},
		async sendMessage(store, { conversationId, message, attachments }) {
			try {
				await window.request("POST", `/messaging/conversations/${conversationId}/events`, {
					message,
					attachments
				});
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async updateConversation(store, { conversationId, payload }) {
			try {
				const res = await window.request(
					"PATCH",
					`/messaging/conversations/${conversationId}`,
					payload
				);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getConversationCounts(store, payload) {
			try {
				const config = {};
				if (payload.userIds.length > 1) {
					config.headers = { "X-User-Id": "MULTIPLE-USER-IDS" };
				}
				const res = await window.request("GET", "/messaging/conversations/count", payload, config);
				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async uploadAttachment(store, file) {
			try {
				const formData = new FormData();
				formData.append("file", file);
				const res = await window.request("POST", `/messaging/attachments`, formData);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getAttachment(store, { conversationId, payload }) {
			try {
				const url = `/messaging/conversations/${conversationId}/attachments`;
				const config = { responseType: "arraybuffer" };
				const res = await window.request("GET", url, payload, config);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		}
	}
};
