import { appendScript } from "@/js/helpers/utils";
import { delayDependsOnConection } from "@/js/helpers/utils";

window.metricsLoaded = false;

export default function thirdPartLibsLazyLoad() {
	if (!window.metricsLoaded) {
		setTimeout(() => {
			connectPostAffTracker();
			connectGTM();
			connectGTag();
			connectFacebookPixel();
		}, delayDependsOnConection(10, 6, 4, 1));
		window.metricsLoaded = true;
	}

	function connectPostAffTracker() {
		const ATTRS = {
			type: "text/javascript",
			id: "pap_x2s6df8d",
			src: "https://www.dripify.io/scripts/93jxx2adz2"
		};
		appendScript(ATTRS, () => {
			window.PostAffTracker.setAccountId("default1");
			try {
				window.PostAffTracker.track();
			} catch (err) {
				console.error(err);
			}
			window.PostAffTracker.getAffInfo().call(window.PostAffTracker.getAffInfo().getAffiliateId());
		});
	}

	function connectGTM() {
		(function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l != "dataLayer" ? "&l=" + l : "";
			j.async = true;
			j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
			f.parentNode.insertBefore(j, f);
		})(window, document, "script", "dataLayer", "GTM-NTXB65R");
	}

	function connectGTag() {
		const ATTRS = {
			src: "https://www.googletagmanager.com/gtag/js?id=UA-170471591-1",
			async: true
		};
		appendScript(ATTRS, () => {
			window.dataLayer = window.dataLayer || [];
			function gtag() {
				window.dataLayer.push(arguments);
			}
			gtag("js", new Date());
			gtag("config", "UA-170471591-1");
		});
	}

	function connectFacebookPixel() {
		!(function (f, b, e, v, n, t, s) {
			if (f.fbq) return;
			n = f.fbq = function () {
				n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
			};
			if (!f._fbq) f._fbq = n;
			n.push = n;
			n.loaded = !0;
			n.version = "2.0";
			n.queue = [];
			t = b.createElement(e);
			t.async = !0;
			t.src = v;
			s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s);
		})(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
		window.fbq("init", "281472513306882");
		window.fbq("track", "PageView");
	}
}
