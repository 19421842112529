<template>
	<section
		class="add-leads"
		aria-label="Campaign leads adding"
	>
		<div class="head">
			<h1 class="title">Lists of leads</h1>
			<button
				v-if="items.length"
				class="btn btn--base"
				aria-haspopup="true"
				:disabled="campaign.locked"
				@click="openAudiencePopup"
			>
				{{ campaignEdit ? "Add leads" : "Create a new list" }}
			</button>
		</div>
		<div v-if="preload">
			<div v-if="preloadItems">
				<LeadsPackPreload
					v-for="i in preloadItems"
					:key="i"
				/>
			</div>
			<Preloader v-else />
		</div>
		<div v-else-if="items.length">
			<span id="skip-leads-lists-up" />
			<a
				v-if="items.length > 1"
				href="#skip-leads-lists-down"
				class="acccessible-skip"
			>Skip leads lists and go down</a>
			<ul
				class="add-leads__list"
				aria-label="Leads lists"
			>
				<li
					v-for="pack in items"
					:key="pack.createdAt"
				>
					<LeadsPack
						:pack="pack"
						:is-draft="isDraft"
						:campaign-creating="campaignCreatingPage"
						:campaign="campaign"
						:statistics-loading="statisticsLoading"
						@removed="removePackage"
					/>
				</li>
			</ul>

			<a
				v-if="items.length > 1"
				href="#skip-leads-lists-up"
				class="acccessible-skip acccessible-skip--bottom"
			>Skip leads lists and go up</a>
			<span id="skip-leads-lists-down" />

			<ListItemsViewManager
				:pg-count="pageCount"
				list-name="lists"
				:pg-size="+pageSize"
				:pg="page"
				@onPageSizeChange="pageSizeChangeHandler"
			/>
		</div>
		<div
			v-else
			class="add-leads__empty"
		>
			<ImgComp
				src="add-leads.svg"
				alt="No leads"
				class="add-leads__empty-img"
			/>
			<span class="add-leads__empty-text"> Add leads from LinkedIn to this campaign </span>
			<button
				class="btn btn--base"
				:disabled="campaign.locked"
				aria-haspopup="true"
				@click="openAudiencePopup"
			>
				Add leads
			</button>
		</div>

		<transition name="fade">
			<Popup
				v-if="popup"
				aria-label="Leads adding (Popup)"
				@close="closeAudiencePopup"
			>
				<template #header>
					<icon
						icon="ready"
						:w="24"
						:h="24"
						stroke="var(--w-color)"
					/>
					<span>Create a list of leads below</span>
				</template>
				<LeadsAdding
					:campaign-id="campaign.id"
					@addingLeads="addPack"
				/>
			</Popup>
		</transition>
	</section>
</template>

<script>
import PaginationMixin from "@/js/mixins/pagination.mixin";
import getLeadPacksStatistics from "@/js/mixins/getLeadPacksStatistics.mixin";
import LeadsPack from "@/js/components/Campaign/Audience/LeadsPack";
import LeadsPackPreload from "@/js/components/Campaign/Audience/LeadsPackPreload";
import LeadsAdding from "@/js/components/Campaign/Audience/LeadsAdding";

export default {
	name: "Audience",
	components: {
		LeadsAdding,
		LeadsPack,
		LeadsPackPreload
	},
	mixins: [getLeadPacksStatistics, PaginationMixin],
	props: {
		campaignEdit: { type: Boolean, required: false },
		campaign: { type: Object, required: true }
	},
	data: () => ({
		preload: true,
		popup: false,
		deletePopup: false,
		items: [],
		listsResponse: null,
		isDraft: false
	}),
	computed: {
		campaignCreatingPage() {
			return this.$route.name === "campaign-create";
		},
		preloadItems() {
			const campStat = this.campaign.statistics;
			return campStat ? campStat.leadsListsSize : this.items.length;
		}
	},
	watch: {
		pageNumber(newVal) {
			this.pageChangeHandler(newVal);
		}
	},
	async mounted() {
		try {
			this.pageSize = localStorage.getItem("listsPageSize") || this.pageSize;
			if (this.campaignEdit) {
				this.isDraft = this.campaign.status === "DRAFT";
			}
			if (this.addLeadsQuery) {
				this.openAudiencePopup();
			}
			await this.pageChangeHandler(this.pageNumber);
		} finally {
			this.preload = false;
		}
	},
	methods: {
		async pageChangeHandler(page = 1) {
			this.listsResponse = await this.getListItems("getSearchLeadsConfigItems", page, {
				campaignId: this.campaign.id,
				sort: "createdAt,desc"
			});
			this.items = this.listsResponse.content;
			if (this.campaignEdit) {
				await this.getLeadPacksStatistics(this.items);
			}
		},
		onPacksChange() {
			this.$emit("packsCountChanged", this.items.length);
		},
		async addPack() {
			await this.pageChangeHandler();
			this.closeAudiencePopup();
			this.onPacksChange();
		},
		closeAudiencePopup() {
			this.popup = false;
		},
		openAudiencePopup() {
			this.popup = true;
		},
		async removePackage() {
			await this.pageChangeHandler();
			this.onPacksChange();
		},
		pageSizeChangeHandler(val) {
			this.pageSize = val;
			localStorage.setItem("listsPageSize", this.pageSize);
			this.pageChangeHandler();
		}
	}
};
</script>

<style lang="sass">
.add-leads
	position: relative
	&__
		&list
			margin-bottom: 30px
		&empty
			+flex(center, center, column)
			height: 300px
			gap: 17px
		&empty-img
			max-width: 200px
			width: 50%
		&empty-text
			text-align: center
</style>
