class Serializer {
	constructor(func, options) {
		/*
			---options---
			refetchOnWindowFocus,
			refetchFocusInterval,
			refetchInterval,
			refetchOnReconnect
		*/

		this.options = options;
		this.func = func;
		this.refetchInterval = null;
		this.lastRequestAt = null;

		this.run();
		this.setRequestTime();
	}

	run() {
		const isDev = process.env.NODE_ENV === "development";

		if (this.options.refetchOnWindowFocus && !isDev) {
			window.addEventListener("focus", () => {
				const DEFAULT_REFETCH_FOCUS_INTERVAL = 3;
				const windowRefocusTimeout =
					60000 * (this.options.refetchFocusInterval || DEFAULT_REFETCH_FOCUS_INTERVAL); // interval beetween last and next requests after window focus
				const enoughPauseTimeBetweenRequests =
					Date.now() - this.lastRequestAt > windowRefocusTimeout;

				if (!this.lastRequestAt || enoughPauseTimeBetweenRequests) this.fetchHandler();
			});
		}

		if (this.options.refetchInterval) {
			const minutes = 60000 * this.options.refetchInterval;

			this.refetchInterval = setInterval(() => {
				this.fetchHandler();
			}, minutes);
		}

		if (this.options.refetchOnReconnect) {
			window.addEventListener("online", this.fetchHandler);
		}
	}

	stop() {
		window.removeEventListener("focus", this.fetchHandler);
		window.removeEventListener("online", this.fetchHandler);
		clearInterval(this.refetchInterval);
	}

	setRequestTime() {
		this.lastRequestAt = Date.now();
	}

	fetchHandler() {
		this.setRequestTime();
		this.func();
	}
}

window.Serializer = Serializer;
