<template>
	<div
		id="app"
		role="application"
		aria-label="Dripify — LinkedIn automation tool"
	>
		<component
			:is="layout"
			v-if="layout"
			v-show="emailVerified"
			ref="layout"
			@layoutLoaded="thirdPartLibsLazyLoad"
		>
			<transition
				name="fade"
				mode="out-in"
			>
				<router-view />
			</transition>
		</component>

		<EmailVerify v-if="!emailVerified" />
		<TwoStepVerification v-if="twoStepFAenabled" />
		<ConfirmLinkedin
			v-else-if="!linkedinConfirmed"
			@onConfirm="checkCurrentUserStatusToError"
		/>
		<CaptchaWaitingPopup v-else-if="captchaRequested" />
		<InvitationPopup v-else-if="haveInvitation" />

		<Toasts />
		<VueAnnouncer />
		<PseudoSupportChat />
	</div>
</template>

<script>
import eventBus from "@/event-bus";
import adminAccountController from "@/js/helpers/adminAccountController";
import PseudoSupportChat from "@/js/components/PseudoSupportChat";
import thirdPartLibsLazyLoad from "@/js/helpers/api/thirdPartLibsLazyLoad";

export default {
	name: "Dripify",
	metaInfo: {
		title: "Dripify"
	},
	components: {
		AuthLayout: () => window.compLoader(import("@/js/layouts/Auth")),
		DefaultLayout: () => window.compLoader(import("@/js/layouts/Default")),
		EmptyLayout: () => window.compLoader(import("@/js/layouts/Empty")),
		InvitationPopup: () => window.compLoader(import("@/js/components/InvitationPopup")),
		EmailVerify: () => window.compLoader(import("@/js/components/Onboarding/EmailVerify")),
		ConfirmLinkedin: () => window.compLoader(import("@/js/components/Onboarding/ConfirmLinkedin")),
		TwoStepVerification: () =>
			window.compLoader(import("@/js/components/Onboarding/TwoStepVerification")),
		CaptchaWaitingPopup: () =>
			window.compLoader(import("@/js/components/Onboarding/CaptchaWaitingPopup")),
		Toasts: () => window.compLoader(import("@/js/components/Toasts/Toasts")),
		PseudoSupportChat
	},
	data: () => ({
		twoStepFAenabled: false,
		linkedinConfirmed: true
	}),
	computed: {
		layout() {
			const layout = this.$route.meta.layout;
			return layout ? `${layout}-layout` : null;
		},
		cu() {
			return this.$store.getters.currentUser;
		},
		cuStatus() {
			return this.$store.getters.userStatus;
		},
		captchaRequested() {
			return this.cuStatus === "CAPTCHA_IN_PROGRESS";
		},
		systemToast() {
			return this.$store.getters.toast;
		},
		haveInvitation() {
			return this.$route.query.invite;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
		emailVerified() {
			return this.$store.getters.emailVerified;
		}
	},
	watch: {
		systemToast(newErr) {
			if (newErr) {
				const message = newErr.status === 500 ? "Oops... Something went wrong" : newErr.message;
				this.$toast(message || newErr, "assertive");
				this.$store.commit("setMessage", "");
			}
		},
		cuStatus() {
			this.checkCurrentUserStatusToError();
		}
	},
	mounted() {
		window.multitabChannel.addEventListener("message", (event) => {
			if (event.data === "refresh") document.location.reload(true);
		});
		adminAccountController.writeTestAcc();

		window.VueApp.prototype.$cu = () => this.cu;
	},
	methods: {
		thirdPartLibsLazyLoad,
		checkCurrentUserStatusToError() {
			this.twoStepFAenabled = this.cuStatus.includes("TWO_FA");
			this.linkedinConfirmed =
				this.cuStatus !== "PIN_CHALLENGED" && this.cuStatus !== "WRONG_VERIFICATION_CODE";
			if (!this.twoStepFAenabled && this.linkedinConfirmed && !this.captchaRequested) {
				eventBus.$emit("noAccountStatusTroubles");
			}
		}
	}
};
</script>

<style lang="sass">
@import "@/styles/app"
@import "@/styles/transitions"
</style>
