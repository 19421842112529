<template>
	<div
		class="inline-spinner"
		:class="{ 'inline-spinner--center': center }"
		:style="`--size: ${size}px`"
	/>
</template>

<script>
export default {
	name: "InlineSpinner",
	props: {
		size: { type: Number, default: 15 },
		center: { type: Boolean, default: false }
	}
};
</script>

<style lang="sass">
.inline-spinner
	display: inline-block
	width: var(--size)
	height: var(--size)
	border-radius: 50%
	background: url(/assets/loader.png) center no-repeat
	background-size: contain
	animation: spinner 1s infinite linear
	cursor: progress
	&--center
		display: block
		margin: 0 auto

@keyframes spinner
	0%
		transform: rotate(0deg)
	100%
		transform: rotate(360deg)
</style>
