import Vue from "vue";
import store from "@/js/store";
import router from "@/js/router";

import InlineSpinner from "@/js/components/Spinners/InlineSpinner";
import PasswordInput from "@/js/components/Form/PasswordInput";
import Preloader from "@/js/components/Spinners/Preloader";
import Spinner from "@/js/components/Spinners/Spinner";
import TextInput from "@/js/components/Form/TextInput";
import AsyncButton from "@/js/components/AsyncButton";
import Checkbox from "@/js/components/Form/Checkbox";
import CleanPopup from "@/js/components/CleanPopup";
import InfoTip from "@/js/components/InfoTip";
import ImgComp from "@/js/components/ImgComp";
import Icon from "@/js/components/Icon";
import App from "@/js/App";

import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";

import { setSupportChatOptions } from "@/js/helpers/api/supportChat";
import VTooltip from "v-tooltip";
import VueMeta from "vue-meta";
import VueAnnouncer from "@vue-a11y/announcer";

import "@/js/helpers/correctDate";
import "@/js/helpers/planDetails";
import "@/js/helpers/validation";
import "@/js/helpers/api/requestsHandler";
import "@/js/helpers/serializer";
import "@/js/helpers/ripple";
import "@/js/helpers/componentLoader";

Vue.component("InlineSpinner", InlineSpinner);
Vue.component("PasswordInput", PasswordInput);
Vue.component("AsyncButton", AsyncButton);
Vue.component("CleanPopup", CleanPopup);
Vue.component("TextInput", TextInput);
Vue.component("Preloader", Preloader);
Vue.component("Checkbox", Checkbox);
Vue.component("Spinner", Spinner);
Vue.component("ImgComp", ImgComp);
Vue.component("InfoTip", InfoTip);
Vue.component("Icon", Icon);

Vue.use(VueMeta);
Vue.use(VueAnnouncer);
Vue.use(VTooltip, {
	defaultTrigger: "hover click"
});
window.VueApp = Vue;

Vue.config.productionTip = false;

firebase.initializeApp({
	apiKey: process.env.VUE_APP_APIKEY,
	authDomain: process.env.VUE_APP_AUTH_DOMAIN,
	databaseURL: process.env.VUE_APP_DATABASE_URL,
	projectId: process.env.VUE_APP_PROJECT_ID,
	storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
	appId: process.env.VUE_APP_APP_ID
});

window.multitabChannel = new BroadcastChannel("multitab");

let app;

firebase.auth().onAuthStateChanged((userInfo) => {
	if (!app) {
		app = new Vue({
			router,
			store,
			render: (h) => h(App)
		}).$mount("#app");
	}

	// refresh other tabs on every authentication change event
	window.multitabChannel.postMessage(userInfo ? "login" : "logout");

	setSupportChatOptions(userInfo);
});
