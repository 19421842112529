export default {
	methods: {
		async getCampaignsIfBasicPlan() {
			if (this.$isBasicPlan()) {
				const res = await this.$store.dispatch("getCampaigns", {
					page: 0,
					size: 2,
					empty: true,
					sort: ["locked", "status", "createdAt,asc"]
				});
				this.$store.commit("setCampaignsInBasic", res.content);
			}
		}
	}
};
