<template>
	<div
		v-if="statisticsLoading"
		class="leads-indicators"
	>
		<div class="leads-indicator-placeholder" />
		<div class="leads-indicator-placeholder" />
		<div class="leads-indicator-placeholder" />
		<div class="leads-indicator-placeholder" />
		<div class="leads-indicator-placeholder" />
	</div>
	<div
		v-else-if="statistics"
		class="leads-indicators"
		:style="indicatorsGrid"
		role="progressbar"
		aria-label="Lead's progress"
	>
		<LeadsStatusIndicator
			v-for="(indicator, idx) in indicatorsList"
			v-show="indicator.value"
			:key="idx"
			:indicator="indicator"
			:campaign="campaign"
			:for-leads-packs="forLeadsPacks"
			:pack="pack"
			:disabled="disabled"
		/>
	</div>
</template>

<script>
export default {
	name: "LeadsIndicators",
	components: {
		LeadsStatusIndicator: () =>
			window.compLoader(import("@/js/components/Campaign/LeadsIndicators/LeadsStatusIndicator"))
	},
	props: {
		statistics: { type: Object, required: false },
		statisticsLoading: { type: Boolean, default: false },
		campaign: { type: Object, required: true },
		pack: { type: Object, required: false },
		forLeadsPacks: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false }
	},
	computed: {
		indicatorsList() {
			return this.forLeadsPacks ? this.leadsPacksIndicators : this.campaignIndicators;
		},
		campaignIndicators() {
			return [
				{
					id: "ended",
					value:
						this.statistics.finishedLeads +
						this.statistics.failedLeads +
						this.statistics.blacklistedLeads
				},
				{
					id: "holded",
					value: this.statistics.pausedLeads + this.statistics.waitingLeads
				},
				{
					id: "progress",
					value: this.statistics.inProgressLeads
				},
				{
					id: "remaining",
					value: this.statistics.gettingReadyLeads
				},
				{
					id: "uploading",
					value: this.statistics.uploadingLeads
				}
			];
		},
		leadsPacksIndicators() {
			return [
				{
					id: "ended",
					value:
						this.statistics.finishedLeadsCount +
						this.statistics.failedLeadsCount +
						this.statistics.blackListedCount
				},
				{
					id: "holded",
					value: this.statistics.pausedCount + this.statistics.waitingUntilCount
				},
				{
					id: "progress",
					value: this.statistics.inProgressLeadsCount
				},
				{
					id: "remaining",
					value: this.statistics.gettingReadyLeads
				},
				{
					id: "uploading",
					value: this.statistics.uploadingCount
				}
			];
		},
		indicatorsGrid() {
			let gridVal = "";
			this.indicatorsList.forEach((item) => {
				if (item.value) gridVal += `${item.value}fr `;
			});
			return `grid-template-columns: ${gridVal}`;
		}
	}
};
</script>

<style lang="sass">
.leads-indicators
	display: grid
	grid-template-columns: repeat(5, 1fr)
	gap: 4px

.leads-indicator-placeholder
	height: 6px
	border-radius: 4px
	background-color: cl(easy-blue)
	animation: blinking .9s infinite
	&:nth-child(1)
		animation-delay: 0s
	&:nth-child(2)
		animation-delay: .2s
	&:nth-child(3)
		animation-delay: .4s
	&:nth-child(4)
		animation-delay: .6s
	&:nth-child(5)
		animation-delay: .8s
</style>
