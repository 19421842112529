import ComponentError from "@/js/components/ComponentError";
import StaticPreloader from "@/js/components/Spinners/StaticPreloader";

export default function compLoader(component, preloader, delay = 300) {
	const AsyncHandler = () => ({
		component: component,
		error: ComponentError,
		loading: preloader ? StaticPreloader : null,
		delay: delay
	});

	return Promise.resolve({
		functional: true,
		render(h, { data, children }) {
			return h(AsyncHandler, data, children);
		}
	});
}
window.compLoader = compLoader;
