import Vue from "vue";
import { PLANS_PRICES } from "@/js/helpers/constants/constants";
import { capitalizeFirstLetter } from "@/js/helpers/utils";
import { BEST_PLAN_ID, PLANS_LIST } from "@/js/helpers/constants/constants";

import store from "@/js/store/index";

const planDetails = {
	capitalizeFirstLetter,
	myPlanId() {
		return store.getters.myPlanId;
	},
	myEfPlanId() {
		return store.getters.efPlanId;
	},
	planFullName(planId, capitalized) {
		planId = planId || this.myPlanId();

		return `${this.planShortName(planId, capitalized)} - ${this.planPaymentPeriod(
			planId,
			capitalized
		)}`;
	},
	planShortName(planId, capitalized) {
		planId = planId || this.myPlanId();
		const splitedId = planId.split("-")[0];

		if (this.isDripifySubscriptionPlan(planId)) {
			return capitalized ? capitalizeFirstLetter(splitedId) : splitedId;
		} else {
			switch (splitedId) {
			case "small":
				return "S";
			case "medium":
				return "M";
			case "large":
				return "L";
			case "extralarge":
				return "XL";
			default:
				return "";
			}
		}
	},
	planPaymentPeriod(planId, capitalized) {
		planId = planId || this.myPlanId();
		const splitedId = planId.split("-")[1];

		return capitalized ? capitalizeFirstLetter(splitedId) : splitedId;
	},
	haveBestPlan(planId) {
		planId = planId || this.myPlanId();

		return planId === BEST_PLAN_ID;
	},
	isBasicPlan(planId) {
		return this.planShortName(planId || this.myPlanId()) === "basic";
	},
	isAdvancedPlan(planId) {
		return this.planShortName(planId || this.myPlanId()) === "advanced";
	},
	isMonthlyPlanPeriod(planId) {
		return this.planPaymentPeriod(planId || this.myPlanId()) === "monthly";
	},
	hasActiveSubscription(status, nextProductId) {
		const inTransition = nextProductId && status === "canceled";
		return status === "active" || status === "overdue" || inTransition;
	},
	isDripifySubscriptionPlan(planId) {
		return PLANS_LIST.includes(planId);
	},
	efPlanCreditsCount(planId) {
		planId = planId || this.myEfPlanId();
		const defaultValue = "100";

		if (planId) {
			const splitedId = planId.split("-")[0];

			switch (splitedId) {
			case "small":
				return "1 000";
			case "medium":
				return "2 000";
			case "large":
				return "5 000";
			case "extralarge":
				return "10 000";
			default:
				return defaultValue;
			}
		} else {
			return defaultValue;
		}
	},
	priceByPeriod(subscription) {
		if (subscription) {
			const { priceId } = subscription;
			const isMonthly = this.isMonthlyPlanPeriod(priceId);
			const price = (isMonthly ? PLANS_PRICES[priceId] : PLANS_PRICES[priceId] * 12).toFixed(1);
			const period = isMonthly ? "month" : "year";
			return `$${price}/${period}`;
		} else {
			return "Free trial";
		}
	},
	isPausedOrScheduled(subscription) {
		return (
			subscription &&
			((subscription.status === "active" && subscription.pausedAt) ||
				subscription.status === "paused")
		);
	}
};

for (let key in planDetails) {
	Vue.prototype["$" + key] = (arg1, arg2) => planDetails[key](arg1, arg2);
}
