<template>
	<div class="camp-create">
		<WellDone v-if="done" />
		<template v-else>
			<Preloader v-if="preloader" />
			<div class="card">
				<div class="camp-create__steps">
					<ol
						class="camp-create__container"
						aria-label="Campaign creation steps"
					>
						<li
							v-for="(stp, index) in steps"
							:key="index"
							:class="stepClass(index + 1)"
							class="camp-create__step"
						>
							{{ stp }}
						</li>
					</ol>
				</div>
				<hr class="card__hr">
				<div class="camp-create__view">
					<transition
						:name="transitionMode"
						mode="out-in"
					>
						<keep-alive>
							<component
								:is="'Step' + step"
								v-if="loadingComplete"
								:campaign="draft"
								@change="addChangesInDraft"
								@changeStep="onStepChange"
								@packsCountChanged="(val) => (havePacks = !!val)"
								@onSequenceChange="(val) => (draftSequence = val)"
								@save="onSave"
							/>
						</keep-alive>
					</transition>
				</div>
			</div>
		</template>

		<transition name="fade">
			<Popup
				v-if="stopPopup"
				small
				aria-label="Save campaign before leave (Popup)"
				@close="closePopup"
			>
				<template #header>
					<span>Save changes</span>
				</template>
				<p>
					Would you like to save this campaign as
					{{ campaignHasAllRequirements ? "non-active" : "a draft" }}?
				</p>
				<br>
				<div class="popup__footer">
					<AsyncButton
						:async-f="openSavingPopup"
						class="btn btn--accent"
					>
						Yes, save it
					</AsyncButton>

					<AsyncButton
						:async-f="deletaDraftAndLeavePage"
						class="btn btn--base-empty"
					>
						No
					</AsyncButton>
				</div>
			</Popup>
		</transition>

		<transition name="fade">
			<Popup
				v-if="savingPopup"
				small
				aria-label="Save draft (Popup)"
				@close="closePopup"
			>
				<template #header>
					<span>Enter draft name</span>
				</template>

				<ValidationObserver v-slot="{ passes, invalid }">
					<form @submit.prevent="passes(updateDraft)">
						<TextInput
							v-model="draftName"
							input-name="draftName"
							label-text="Draft name"
							validation="required"
							selected
						/>
						<footer class="popup__footer">
							<button
								class="btn btn--accent"
								type="submit"
								:disabled="invalid || loading"
							>
								<Spinner v-if="loading" />
								<span v-else>Save & continue</span>
							</button>
						</footer>
					</form>
				</ValidationObserver>
			</Popup>
		</transition>
	</div>
</template>

<script>
import Step1 from "@/js/views/Campaigns/CampaignCreating/Step1";
import adminAccountController from "@/js/helpers/adminAccountController";
import getCampaignsIfBasicPlan from "@/js/mixins/getCampaignsIfBasicPlan.mixin";
import { UPGRADE_TO_CREATE_MORE_CAMPAIGNS } from "@/js/helpers/constants/app-texts";

export default {
	name: "CampaingCreate",
	metaInfo: {
		title: "Create a campaign"
	},
	components: {
		Step1,
		Step2: () => window.compLoader(import("@/js/views/Campaigns/CampaignCreating/Step2")),
		Step3: () => window.compLoader(import("@/js/views/Campaigns/CampaignCreating/Step3")),
		WellDone: () => window.compLoader(import("@/js/components/Campaign/WellDone"))
	},
	mixins: [getCampaignsIfBasicPlan],
	async beforeRouteLeave(to, from, next) {
		if (!this.saving && !this.noMoreCampaigns) {
			try {
				this.preloader = true;
				const savedDraftName = this.draft.name;
				await this.$store.dispatch("saveSequence", {
					campaignId: this.draft.id,
					sequenceItemRequest: this.draftSequence
				});
				this.draft = await this.$store.dispatch("getCampaignById", this.draft.id);
				this.draft.name = savedDraftName;
				this.saving = true;
				this.leavingPath = to.path;
				this.stopPopup = true;
			} finally {
				this.preloader = false;
			}
			next(false);
			return;
		}
		next();
	},
	data: () => ({
		step: 1,
		stopPopup: false,
		savingPopup: false,
		saving: false,
		transitionMode: "next",
		leavingPath: null,
		loading: false,
		done: false,
		steps: ["Add leads", "Create a sequence", "Summarize and launch"],
		loadingComplete: false,
		draft: null,
		draftName: "",
		preloader: false,
		havePacks: false,
		draftSequence: null
	}),
	computed: {
		campaignId() {
			return this.campaign ? this.campaign.id : null;
		},
		noMoreCampaigns() {
			return this.$store.getters.depricateCreatingNewCampaigns;
		},
		campaignHasAllRequirements() {
			return this.havePacks && this.draft.sequenceCompleted;
		}
	},
	async created() {
		try {
			await this.getCampaignsIfBasicPlan();
			if (this.noMoreCampaigns) {
				this.$toast(UPGRADE_TO_CREATE_MORE_CAMPAIGNS);
				this.$router.push("/upgrade");
			} else {
				await this.createDraft();
				window.addEventListener("beforeunload", this.tabLeavingHandler);
			}
		} finally {
			this.loadingComplete = true;
		}
	},
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.tabLeavingHandler);
	},
	methods: {
		async createDraft() {
			this.draft = await this.$store.dispatch("createCampaign", {
				name: this.createCampaignNameBasedTime(),
				type: "CUSTOM_CAMPAIGN",
				status: "DRAFT",
				zapierUrl: "",
				skipLeadConditions: ["CAMPAIGN_DUPLICATE"]
			});
			this.draft.name = "";
			this.getCampaignsIfBasicPlan();
		},
		addChangesInDraft(options) {
			Object.keys(options).forEach((key) => {
				if (options[key] || key === "name") this.draft[key] = options[key];
			});
		},
		onStepChange(stepDirection) {
			this.transitionMode = stepDirection;
			if (stepDirection === "next") this.step += 1;
			else this.step -= 1;
		},
		stepClass(index) {
			return {
				"camp-create__step--active": this.step === index,
				"camp-create__step--done": index < this.step
			};
		},
		createCampaignNameBasedTime() {
			return this.$dd_mm_yyyy() + " " + this.$hh_mm();
		},
		openSavingPopup() {
			if (this.campaignHasAllRequirements && this.draft.name) {
				this.updateDraft();
			} else {
				this.draftName = this.draft.name || this.createCampaignNameBasedTime();
				this.stopPopup = false;
				this.savingPopup = true;
			}
		},
		async updateDraft() {
			try {
				this.loading = true;
				this.draft.status = this.campaignHasAllRequirements ? "NOT_ACTIVE" : "DRAFT";
				if (this.draftName) this.draft.name = this.draftName;
				await this.$store.dispatch("updateCampaign", this.draft);
				this.$toast(`Your campaign has been successfully saved ${!this.campaignHasAllRequirements ? "as draft" : ""}`);
				this.leavePage();
			} finally {
				this.loading = false;
			}
		},
		closePopup() {
			this.stopPopup = false;
			this.savingPopup = false;
			this.saving = false;
		},
		async deletaDraftAndLeavePage() {
			await this.$store.dispatch("deleteCampaign", this.draft.id);
			this.leavePage();
		},
		leavePage() {
			this.stopPopup = false;
			this.savingPopup = false;
			setTimeout(() => {
				this.$router.push(this.leavingPath);
			}, 300);
		},
		tabLeavingHandler(event) {
			if (!adminAccountController.devMode()) {
				event.preventDefault();
				return (event.returnValue = "Would you like to save this campaign before leaving?");
			}
		},
		onSave() {
			this.saving = true;
			this.done = true;
		}
	}
};
</script>

<style lang="sass">
.camp-create
	&__
		&steps
			padding-bottom: 20px
			counter-reset: steps 0
		&step
			font-size: 15px
			display: flex
			align-items: center
			color: cl(_gray)
			transition: color var(--transition)
			&::before
				counter-increment: steps 1
				content: counter(steps, decimal)
				height: 30px
				width: 30px
				border-radius: 50%
				text-align: center
				background-color: cl(light-dirty)
				color: cl(w)
				padding-top: 6px
				margin-right: 10px
				font-size: 15px
				transition: background-color var(--transition)
			@media screen and (max-width: $tablet)
				font-size: 0
				display: flex
				justify-content: center
			&--active
				color: cl(b)
				&::before
					background-color: cl(base-theme)
			&--done
				&::before
					content: ''
					background: cl(soft-theme) center no-repeat url("data:image/svg+xml,%3Csvg width='17' height='12' viewBox='0 0 17 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 1L5.6875 11L1 6.45455' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")
		&container
			display: grid
			grid-template-columns: repeat(3, 1fr)
			gap: 20px
			max-width: 1005px
			width: 100%
			margin: 0 auto
</style>
