<template>
	<div
		class="preloader"
		:class="{
			'preloader--bg': bg,
			'preloader--static': staticPosition
		}"
		:style="{ 'background-color': bgc }"
	>
		<img
			v-show="loaded"
			src="/assets/logo_animation.webp"
			alt="Dripify"
			class="preloader__img"
			@load="onLoad"
		>
		<!-- showing a static logo before logo_animation loaded -->
		<svg
			v-show="!loaded"
			xmlns="http://www.w3.org/2000/svg"
			width="50"
			height="18"
			viewBox="0 0 60 22"
			fill="none"
		>
			<path
				d="M57.576.034L46.69 15.806a13.235 13.235 0 01-4.75 4.2 13.283 13.283 0 01-6.165 1.516h-4.667L42.022 5.737a13.235 13.235 0 014.75-4.201A13.282 13.282 0 0152.936.02l4.64.014zM42.521.034L31.634 15.806a13.24 13.24 0 01-4.766 4.208 13.288 13.288 0 01-6.184 1.508h-4.667L26.932 5.737a13.242 13.242 0 014.766-4.208A13.29 13.29 0 0137.882.02l4.64.014zM27.467.034L16.579 15.806a13.24 13.24 0 01-4.765 4.208 13.288 13.288 0 01-6.184 1.508H.963L11.878 5.737a13.242 13.242 0 014.765-4.208A13.29 13.29 0 0122.827.02l4.64.014z"
				fill="#FD9966"
			/>
			<path
				d="M57.576.034L46.69 15.806a13.235 13.235 0 01-4.75 4.2 13.283 13.283 0 01-6.165 1.516h-4.667C45.354 21.536 48.632.034 57.577.034zM42.521.034L31.634 15.806a13.235 13.235 0 01-4.75 4.2 13.283 13.283 0 01-6.166 1.516h-4.667C30.3 21.536 33.577.034 42.521.034zM27.466.034L16.578 15.806a13.235 13.235 0 01-4.75 4.2 13.283 13.283 0 01-6.165 1.516H.996C15.244 21.536 18.522.034 27.466.034z"
				fill="#CE6E4D"
			/>
		</svg>
	</div>
</template>

<script>
export default {
	name: "Preloader",
	props: {
		bg: { type: [Boolean, String], default: false },
		staticPosition: { type: Boolean, default: false }
	},
	data: () => ({
		loaded: false
	}),
	computed: {
		bgc() {
			return typeof this.bg === "string" ? this.bg : null;
		}
	},
	methods: {
		onLoad() {
			this.loaded = true;
		}
	}
};
</script>

<style lang="sass">
.preloader
	position: absolute
	z-index: 2
	height: 100%
	width: 100%
	overflow: visible
	top: 0
	left: 0
	display: flex
	align-items: center
	justify-content: center
	img
		width: 100px
	&--
		&bg
			background-color: cl(b, .3)
			@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)
				-webkit-backdrop-filter: blur(2px)
				backdrop-filter: blur(2px)
		&static
			position: static
			height: 300px
</style>
