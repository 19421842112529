<template>
	<section
		v-if="isActive"
		:id="computedId"
		role="tabpanel"
	>
		<slot />
	</section>
</template>

<script>
export default {
	name: "Tab",
	props: {
		name: {
			required: true,
			type: String
		},
		disabled: {
			required: false,
			type: Boolean
		}
	},
	data: () => ({
		isActive: false
	}),
	computed: {
		computedId() {
			return this.name.toLowerCase().replace(/ /g, "-");
		}
	}
};
</script>
