import ConditionsList from "@/js/helpers/sequence/conditions";

export default {
	mixins: [ConditionsList],
	data: () => ({
		ConditionsList
	}),
	methods: {
		actionInNoBranch(action) {
			if (!action || !action.parent || action.noDelay) {
				return false;
			}
			if (action.parent && action.parent.no === action) {
				return true;
			}
			return this.actionInNoBranch(action.parent);
		},
		isAnyActionOfArrayBeforeFork(action, actionsTypes) {
			if (action.noDelay) {
				return false;
			} else if (actionsTypes.includes(action.type)) {
				return true;
			} else if (action.parent) {
				return this.isAnyActionOfArrayBeforeFork(action.parent, actionsTypes);
			} else {
				return false;
			}
		},
		closestForkIs(action, actionsType) {
			if (action.noDelay) {
				return action.type === actionsType || action.sameLogicType === actionsType;
			} else if (action.parent) {
				return this.closestForkIs(action.parent, actionsType);
			} else {
				return false;
			}
		},
		closestForkIsCondition(action) {
			if (action.noDelay) {
				return this.ConditionsList.find((item) => item.type === action.type);
			} else if (action.parent) {
				return this.closestForkIsCondition(action.parent);
			} else {
				return false;
			}
		},
		getArrayOfParentActions(action, arr) {
			arr.push(action.type);
			if (action.parent) return this.getArrayOfParentActions(action.parent, arr);
			else return arr;
		},
		isWithdrawed(action) {
			const arrayOfParentActions = this.getArrayOfParentActions(action, []);
			const connectActionIndex = arrayOfParentActions.findIndex((actionType) =>
				actionType.startsWith("CONNECT")
			);
			if (arrayOfParentActions.includes("WITHDRAW") && connectActionIndex !== -1) {
				return (
					arrayOfParentActions.findIndex((actionType) => actionType === "WITHDRAW") <
					connectActionIndex
				);
			}
			return false;
		},
		delayName(action) {
			return action.parent && action.parent.noDelay && action.parent.no === action
				? "noDelay"
				: "yesDelay";
		},
		getDelayInTimeUnits(delayObj, timeUnit = "HOURS") {
			if (timeUnit === "HOURS")
				return delayObj.timeUnit === "DAYS" ? delayObj.value * 24 : delayObj.value;
			else if (timeUnit === "DAYS")
				return delayObj.timeUnit === "HOURS" ? delayObj.value / 24 : delayObj.value;
		},
		getSummaryDelayFromLastWithdraw(action, sum = 0) {
			const delayObj = action.parent[this.delayName(action)];
			const currentActionHours = this.getDelayInTimeUnits(delayObj);

			if (action.type === "WITHDRAW") return sum;
			else return this.getSummaryDelayFromLastWithdraw(action.parent, currentActionHours + sum);
		}
	}
};
