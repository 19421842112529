// functional for effective Default layout loading
export function setStatusInStorage(status) {
	localStorage.setItem("userStatus", status);
}
export function getStatusFromStorage() {
	return localStorage.getItem("userStatus");
}
export function removeStatusFromStorage() {
	localStorage.removeItem("userStatus");
}
