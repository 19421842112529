<template>
	<div
		class="trigger"
		:class="{
			'trigger--disabled': disabled,
			'trigger--activity': activity
		}"
	>
		<input
			:id="checkName"
			class="trigger__input"
			type="checkbox"
			:name="checkName"
			:checked="checked"
			:disabled="disabled"
			aria-label="Trigger"
			@change="$emit('change', $event.target.checked)"
		>
		<label
			class="trigger__action"
			:for="checkName"
		>
			<span
				v-if="label"
				class="trigger__label"
			>
				{{ label }}
			</span>
		</label>
	</div>
</template>

<script>
export default {
	name: "Trigger",
	model: {
		prop: "checked",
		event: "change"
	},
	props: {
		value: { type: [String, Boolean], default: `trigger${Date.now()}` },
		checkName: { type: String, required: true },
		label: { type: String, default: "" },
		checked: { type: Boolean, required: false },
		disabled: { type: Boolean, default: false },
		activity: { type: Boolean, default: false }
	}
};
</script>

<style lang="sass">
.trigger
	$this: &
	padding-bottom: calc(var(--size) + (var(--trigger-offset-y) * 2))
	width: var(--w)
	position: relative
	--size: 22px
	--trigger-offset-y: 3px
	--w: 70px
	--unchecked: #{cl(base-theme)}
	--checked: #{cl(soft-theme)}
	--unchecked-bg: #{cl(light-gray)}
	--checked-bg: #{cl(light-gray)}
	@media screen and (max-width: $laptop)
		--size: 14px
		--w: 60px
	&--
		&activity
			--w: 36px
			--size: 15px
			--bdrs: 25px
			--checked-bg: #{cl(soft-theme)}
			--unchecked-bg: #{cl(_gray)}
			--unchecked: #{cl(w)}
			--checked: #{cl(w)}
			#{$this}__input:checked + #{$this}__action::after
				background-image: none
			&#{$this}--disabled
				--checked-bg: #{cl(main-more-contrast-theme)}
				--unchecked-bg: #{cl(main-more-contrast-theme)}
				--unchecked: #{cl(w)}
				--checked: #{cl(w)}

		&disabled
			cursor: not-allowed
			--unchecked: #{cl(_gray)}
			--checked: #{cl(_gray)}
			#{$this}__action
				cursor: not-allowed
	&__
		&input
			position: absolute
			z-index: -999
			width: 1px
			height: 1px
			overflow: hidden
			left: calc(var(--w) / 2)
			top: 12px
			&:checked + #{$this}__action
				&::before
					background-color: var(--checked-bg)
				&::after
					transform: translateX(calc(var(--w) - var(--size) - var(--trigger-offset-y) * 2))
					background-color: var(--checked)
				#{$this}__label
					color: cl(b)
			&:focus-visible + #{$this}__action::before
				+focus-visible()

		&action
			color: cl(b)
			cursor: pointer
			user-select: none
			position: absolute
			left: 0
			top: 0
			width: 100%
			height: 100%
			&::before
				content: ''
				background-color: var(--unchecked-bg)
				width: var(--w)
				display: block
				height: calc(var(--size) + var(--trigger-offset-y) * 2)
				border-radius: var(--bdrs, var(--borderRadius))
				position: absolute
				bottom: 0
				left: 0
				transition: background-color var(--transition)
			&::after
				content: ''
				position: absolute
				width: var(--size)
				height: var(--size)
				left: var(--trigger-offset-y)
				bottom: var(--trigger-offset-y)
				background: var(--unchecked) no-repeat center url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L1 5M3 1L3 5M5 1V5' stroke='white' stroke-linecap='round'/%3E%3C/svg%3E%0A")
				border-radius: var(--bdrs, 2px)
				transition: transform var(--transition), background-color var(--transition)
		&label
			color: cl(_gray)
			transition: color var(--transition)
</style>
