import { capitalizeFirstLetter } from "@/js/helpers/utils";
import { CUSTOM_AVATARS_AND_NAMES_FOR_SUPPORT } from "@/js/helpers/constants/restrictionsExclude";

export default {
	methods: {
		supportAcc(user) {
			let emailSameAsUserName = false
			const userIdIsSame = CUSTOM_AVATARS_AND_NAMES_FOR_SUPPORT[user.id] || CUSTOM_AVATARS_AND_NAMES_FOR_SUPPORT[user.userId];

			Object.keys(CUSTOM_AVATARS_AND_NAMES_FOR_SUPPORT).forEach(id => {
				const supportObj = CUSTOM_AVATARS_AND_NAMES_FOR_SUPPORT[id];
				const email = supportObj.email;

				if (email === user.userName
						|| email === user.email
						|| (user.linkedinCredentialsResponse && user.linkedinCredentialsResponse.userName === email)
				) {
					emailSameAsUserName = supportObj
				}
			});

			return emailSameAsUserName || userIdIsSame;
		},
		userFullName(user) {
			return `${this.userFirstName(user)} ${this.userLastName(user)}`;
		},
		userFirstName(user) {
			const support = this.supportAcc(user);
			if (support) user = support;

			return capitalizeFirstLetter(user.firstName || user.firstname);
		},
		userLastName(user) {
			const support = this.supportAcc(user);
			if (support) user = support;

			return capitalizeFirstLetter(user.lastName || user.lastname);
		}
	}
};
