<template>
	<div
		v-click-outside="blurHandler"
		class="pass-input"
		aria-label="Password input"
	>
		<TextInput
			v-model="password"
			:input-name="inputName"
			:ph="ph"
			:label-text="labelText"
			:validation="validation"
			:validation-mode="validationMode"
			:type="isVisiblePass ? 'text' : 'password'"
			:vid="vid"
			:enterkeyhint="enterkeyhint"
			:autocomplete="newPassword ? 'new-password' : 'off'"
			@input="onInput"
			@focus="focusHandler"
			@onExecutePress="$emit('onExecutePress')"
			@onValidationStateChange="(val) => (isValid = val)"
		/>
		<transition name="fade">
			<button
				v-if="password.length"
				class="pass-input__eye"
				:title="isVisiblePass ? 'Hide password' : 'Show password'"
				@click.prevent="changePassVisibility"
			>
				<Icon :icon="isVisiblePass ? 'closeEye' : 'eye'" />
			</button>
		</transition>
	</div>
</template>

<script>
import eventBus from "@/event-bus";
import ClickOutside from "vue-click-outside";

export default {
	name: "PasswordInput",
	directives: { ClickOutside },
	model: {
		prop: "value",
		event: "input"
	},
	props: {
		value: { type: String, default: "" },
		inputName: { type: String, default: "password" },
		labelText: { type: String, default: "" },
		ph: { type: String, default: "" },
		validation: { type: String, default: "required|min:6" },
		interactivePass: { type: Boolean, default: false },
		newPassword: { type: Boolean, default: false },
		vid: { type: String, default: "password" },
		enterkeyhint: { type: String, required: false },
		validationMode: { type: String, default: "lazy" }
	},
	data: () => ({
		password: "",
		isVisiblePass: false,
		isValid: true
	}),
	watch: {
		value(newVal) {
			this.password = newVal;
		}
	},
	methods: {
		changePassVisibility() {
			const inputEl = this.$el.querySelector("input");
			this.isVisiblePass = !this.isVisiblePass;
			if (this.interactivePass) {
				eventBus.$emit("passVisibleChangeHandler", this.isVisiblePass);
			}
			inputEl.focus();
		},
		onInput(val) {
			this.$emit("input", val);
		},
		focusHandler() {
			if (this.interactivePass) {
				eventBus.$emit("passwordFocused");
			}
		},
		blurHandler() {
			if (this.interactivePass) {
				eventBus.$emit("passwordBlured");
			}
		}
	}
};
</script>

<style lang="sass">
.pass-input
	position: relative
	input
		width: calc(100% - var(--padding-x) * 2)
	&__
		&eye
			display: flex
			align-items: center
			height: 33px
			width: calc(var(--padding-x) * 2)
			position: absolute
			right: 10px
			bottom: 0
			z-index: 2
			cursor: pointer
			&:hover,
			&:focus
				path
					stroke: cl(accent-theme)
</style>
