<template>
	<Icon
		v-tooltip="tooltipOptions"
		:w="size"
		:h="size"
		icon="info"
		:stroke="stroke"
		class="info-tip"
		:tabindex="tip ? '0' : '-1'"
		:aria-label="tipContent"
		role="tooltip"
	/>
</template>

<script>
import { isObject } from "@/js/helpers/utils";

export default {
	name: "InfoTip",
	props: {
		size: { type: [String, Number], default: 14 },
		tip: { type: [String, Object], required: false },
		stroke: { type: String, required: false }
	},
	computed: {
		tooltipOptions() {
			const options = {
				trigger: "hover click focus",
				content: this.tipContent
			};
			if (isObject(this.tip)) {
				Object.assign(options, this.tip);
			}
			return options;
		},
		tipContent() {
			return isObject(this.tip) ? this.tip.content : this.tip;
		}
	}
};
</script>

<style lang="sass">
.info-tip
	cursor: help
	border-radius: 50%
</style>
