export function capitalizeFirstLetter(string) {
	return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}

export function moveArrayElement(arr, oldIndex, newIndex) {
	if (newIndex >= arr.length) {
		let k = newIndex - arr.length + 1;
		while (k--) arr.push(undefined);
	}
	arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
}

export function arrayUnique(array) {
	var a = array.concat();
	for (var i = 0; i < a.length; ++i) {
		for (var j = i + 1; j < a.length; ++j) {
			if (a[i] === a[j]) a.splice(j--, 1);
		}
	}
	return a;
}

export function isObject(obj) {
	return typeof obj === "object" && !Array.isArray(obj) && obj !== null;
}

export function numberWithSpaces(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function diffDates(dayOne, dayTwo) {
	return Math.round((dayOne - dayTwo) / (60 * 60 * 24 * 1000));
}

export function prettifyText(text) {
	return lineBreakText(URLify(cleanFromHTML(text)));
}

export function cleanFromHTML(val) {
	const str = val.toString();
	return str.replace(/<\/?[^>]+(>|$)/g, "");
}

export function lineBreakText(text) {
	return text.replace(/(?:\r\n|\r|\n)/g, "<br>");
}

export function URLify(text) {
	const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

	return text.replace(urlRegex, (url) => {
		const link = url.startsWith("www") ? `http://${url}` : url;
		return `<a target="_blank" href="${link}">${url}</a>`;
	});
}

export function getConnectionType() {
	return "connection" in navigator ? navigator.connection.effectiveType : "4g";
}

export function delayDependsOnConection(worst, bad, good = 0, best = 0) {
	const connectionType = getConnectionType();
	let delayinSeconds = 0;

	if (connectionType === "slow-2g") delayinSeconds = worst;
	else if (connectionType === "2g") delayinSeconds = bad;
	else if (connectionType === "3g") delayinSeconds = good;
	else delayinSeconds = best;

	return delayinSeconds * 1000;
}

export function appendElement(elName, attrs, parentElement = document.body, frame = document) {
	const el = frame.createElement(elName);
	for (const attrName in attrs) {
		const attrVal = attrs[attrName];

		if (typeof attrVal === "boolean") {
			el[attrName] = attrVal;
		} else {
			el.setAttribute(attrName, attrVal);
		}
	}
	parentElement.appendChild(el);
	return el;
}

export function appendScript(attrs, callback) {
	const script = appendElement("script", attrs);
	if (callback) script.onload = callback;
}

export function downloadFileHandler(attrs) {
	const link = appendElement("a", attrs);
	link.click();
	link.remove();
}
export function copyText(text) {
	function createFakeElement(value) {
		var fakeElement = document.createElement("textarea");

		fakeElement.style.fontSize = "12pt";

		fakeElement.style.border = "0";
		fakeElement.style.padding = "0";
		fakeElement.style.margin = "0";

		fakeElement.style.position = "absolute";
		fakeElement.style.left = "-9999px";

		var yPosition = window.pageYOffset || document.documentElement.scrollTop;
		fakeElement.style.top = "".concat(yPosition, "px");
		fakeElement.setAttribute("readonly", "");
		fakeElement.value = value;
		return fakeElement;
	}
	const fakeElement = createFakeElement(text);
	document.body.appendChild(fakeElement);
	fakeElement.select();
	const res = document.execCommand("copy");
	fakeElement.remove();

	return res
}

export function isToday(someDate) {
	const today = new Date();
	const correctSomeDate = new Date(someDate);

	return (
		correctSomeDate.getFullYear() === today.getFullYear() &&
		correctSomeDate.getMonth() === today.getMonth() &&
		correctSomeDate.getDate() === today.getDate()
	);
}
