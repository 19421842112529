import searchItems from "./searchItems";
import sequence from "./sequence";
import { formattingParams } from "@/js/helpers/paramsFormatter";

export default {
	modules: {
		searchItems,
		sequence
	},
	state: {
		sleepModeEnabled: false,
		campaignsInBasic: []
	},
	getters: {
		sleepModeEnabled: (s) => s.sleepModeEnabled,
		campaignsInBasic: (s) => s.campaignsInBasic,
		basicPlanDepricateCreatingCampaigns(s, g) {
			return (
				s.campaignsInBasic.length >= 1 &&
				s.campaignsInBasic.find((camp) => !camp.locked) &&
				g.myPlanId.includes("basic")
			);
		},
		depricateCreatingNewCampaigns(_, g) {
			return g.basicPlanDepricateCreatingCampaigns || g.subscriptionPaused;
		}
	},
	mutations: {
		setCampaignsInBasic(state, arr) {
			state.campaignsInBasic = arr;
		},
		changeSleepMode(state, val) {
			state.sleepModeEnabled = val;
		}
	},
	actions: {
		async getCampaigns(store, payload) {
			try {
				const config = {};
				if (payload.userId) config.headers = { "X-User-Id": payload.userId };
				const res = await window.request("GET", "/campaigns", formattingParams(payload), config);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getCampaignById(_, id) {
			const res = await window.request("GET", `/campaigns/${id}`);

			return res.data;
		},
		async createCampaign(store, payload) {
			try {
				const res = await window.request("POST", "/campaigns", payload);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async updateCampaign(store, payload) {
			try {
				const res = await window.request("PUT", `/campaigns/${payload.id}`, payload);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async changeCampaignStatus(store, { status, id }) {
			try {
				const res = await window.request("PATCH", `/campaigns/${id}`, { status });

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async deleteCampaign(store, id) {
			try {
				await window.request("DELETE", `/campaigns/${id}`);
			} catch (err) {
				if (err.response.data.status !== 409) {
					store.commit("setMessage", err.response.data);
					throw err;
				}
			}
		},
		async getCampaignStatistics(_, { campaignsIds, userId }) {
			const config = { headers: { "X-User-Id": userId } };
			const res = await window.request("GET", `/v1/campaign/statistics/${campaignsIds}`, null, config);

			return res.data;
		},
		async getLeadsIdsFile(store, { campaignId, searchLeadConfigItemId }) {
			try {
				const url = `/campaigns/campaign/${campaignId}/search/${searchLeadConfigItemId}/leadids`;
				const res = await window.request("GET", url, {
					responseType: "blob"
				});

				return res;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async checkWebhook(_, webhookString) {
			const res = await window.request("POST", "/zapier/test", {
				zapierUrl: webhookString
			});

			return res.data;
		},
		async lockCampaigns(store, id) {
			try {
				await window.request("PATCH", `/campaigns/${id}/unlock`);
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		}
	}
};
